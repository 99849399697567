export default {
	register(payload) {
		return Vue.http.post(`api/register`, payload)
	},
	checkUniqueEmail(payload) {
		return Vue.http.post(`api/unique-email-address`, payload)
	},
	validateEmail(payload) {
		return Vue.http.post(`api/validate-email-address`, payload)
	},
	checkUniqueNickname(payload) {
		return Vue.http.post(`api/unique-nick-name`, payload)
	},
	getRandomNickName(payload) {
		return Vue.http.post(`api/get-random-nick-name`, payload)
	},
	registerFinish(token, payload) {
		let url = (token) ? `api/register-finish/${token}` : `api/new-register`
		return Vue.http.post(url, payload)
	},
    getPreActivatedUser(token) {
		return Vue.http.get(`api/get-preactivated-user/${token}`)
	},
	resendConfirmEmail(token, payload) {
		let url = `api/register-resend-or-change-email/${token}`
		return Vue.http.post(url, payload)
	},
	activate(token, payload = {}) {
		return Vue.http.get(`api/activate/${token}`, {params: payload})
	},
	forgotPass(payload) {
		return Vue.http.post(`api/forgotten-password`, payload)
	},
	login(payload) {
		return Vue.http.post(`api/login`, payload)
	},
	autoLogin(payload) {
		return Vue.http.get(`api/auto-login`, {params: payload})
	},
	logout() {
		return Vue.http.get(`api/logout`)
	},
	getUser() {
		return Vue.http.get(`api/user`)
	},
	getMyProfile() {
		return Vue.http.get(`api/my-profile`)
	},
	saveMyProfile(payload) {
		return Vue.http.post(`api/save-profile`, payload)
	},
	saveSettings(payload) {
		return Vue.http.post(`api/save-settings`, payload)
	},
	disableNotifications() {
		return Vue.http.get(`api/disable-notifications`)
	},
	deleteAccount(payload) {
		return Vue.http.get(`api/quit`, payload)
	},
	validateNewEmail(token) {
		return Vue.http.get(`api/validate-new-email-address/${token}`)
	},
	removeBlur() {
		return Vue.http.get(`api/remove-blur`)
	},
	pushSubscribe(payload) {
		return Vue.http.post(`api/register-notification`, payload)
	},
	pushUnsubscribe(payload) {
		return Vue.http.post(`api/deregister-notification`, payload)
	},
	apnDeviceRegister(payload) {
		return Vue.http.post(`api/register-apn-device`, payload)
	},
	trackPushNotificationAction(payload) {
		return Vue.http.post(`api/push-notification/${payload}`)
	},
	claimFreeIcebreaker(payload) {
		return Vue.http.post(`api/claim/sticker/${payload}`)
	},
	markAsReadUpdates(payload) {
		return Vue.http.post('api/updates/mark-as-read', payload)
	},
    completeRegister() {
        return Vue.http.post(`api/complete-register`)
    },
    confirmCompleteRegister(data) {
        return Vue.http.post(`api/confirm-complete-register`, data)
    },
    denyCompleteRegister() {
        return Vue.http.post(`api/deny-complete-register`)
    },
    shownCompleteRegister() {
        return Vue.http.post(`api/shown-complete-register`)
    },
    boostProfile() {
        return Vue.http.post(`api/boost-profile`)
    },
    confirmBoostProfile(data) {
        return Vue.http.post(`api/confirm-boost-profile`, data)
    },
    denyBoostProfile() {
        return Vue.http.post(`api/deny-boost-profile`)
    },
    shownBoostProfile() {
        return Vue.http.post(`api/shown-boost-profile`)
    }
}
