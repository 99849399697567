<template>
  <div :class="`cookies-partners-modal ${!showDesktop ? 'mobile' : ''}`">
    <div class="modalinfo">
      <div class="modalcontent">
        <div class="modalwrapper">
          <div class="modaltext modaltextpartner">

            <div class="marketingcheckbox">
              <input type="checkbox" id="marketingSelectAll" v-model="marketing_selected">
              <label for="marketingSelectAll">{{ 'Advertising (Marketing measurement)' | translate }}</label>
            </div>
            <p class="info">{{ 'These cookies are used in connection with the tracking of actions related to advertisements. For example, these cookies will remember that you have visited {name} and can be used to show you a personalized advertisement on another website. Please check the following links of the platforms where we promote ourselves, Google, Microsoft and Twitter. for more information.' | translate({name: domain_name}) }}</p>

            <template v-if="partners && partners.length" v-for="partner in partners">
              <div class="partnerlist">
                <input type="checkbox" :id="`partnerada${partner.id}-checkbox`" :checked="partner.selected" @change="togglePartner(partner)" />
                <span class="partnername swithtext" :id="`partnerada${partner.id}-switch`" @click="toggleDetails(partner)">
                          {{ partner.name }}
                          <em class="hiddentext" v-if="partner.show" :id="`partnerada${partner.id}-hide`"><span>-</span></em>
                          <em class="showtext" v-else :id="`partnerada${partner.id}-show`">+</em>
                        </span>
              </div>

              <div class="partnerpoints" :id="`partnerada${partner.id}-points`" v-if="partner.show">
                <template v-if="partner.privacy_policy">
                  <p class="partnerpointstitle">{{ 'Privacy Policy' | translate }}</p>
                  <p class="partnerpointscontent"><a :href="partner.privacy_policy" class="partnerlink" target="_blank">{{ partner.privacy_policy }}</a></p>
                </template>
                <template v-if="partner.advertise_policy">
                  <p class="partnerpointscontent"><a :href="partner.advertise_policy" class="partnerlink" target="_blank">{{ partner.advertise_policy }}</a></p>
                </template>
                <template v-if="partner.cookie_policy">
                  <p class="partnerpointstitle">{{ 'Delete cookies' | translate }}</p>
                  <p class="partnerpointscontent"><a :href="partner.cookie_policy" class="partnerlink" target="_blank">{{ partner.cookie_policy }}</a></p>
                </template>
                <template v-if="partner.purpose">
                  <p class="partnerpointstitle">{{ 'Purpose' | translate }}</p>
                  <ul><li v-for="li in partner.purpose.split(/\r?\n/)">{{ li }}</li></ul>
                </template>
              </div>
            </template>

            <div class="buttonconfirm buttonconfirmpartner">
              <button type="button" class="btn selectionbuttonpartner" @click="saveSelection">{{ 'Save selection' | translate | normalize(3) }}</button>
            </div>
            <div class="buttonconfirm buttonrefusepartner">
              <button type="button" class="btn rejectpartnersbutton" @click="rejectAll">{{ 'Reject all' | translate | normalize(3) }}</button>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import cookies_partners_modal from "@views/common/CookiesPartnersModal/cookies_partners_modal"
  export default {
    name: 'cookies-settings-modal',
    mixins: [cookies_partners_modal]
  }
</script>
