export default {
    name: 'logout-confirm',
    methods: {
        confirm() {
            this.$store.dispatch('auth/logout')
                .then(res => {
                    this.$router.push({ name: 'logged-out', params: { status: 'success', fromRemoveAuth: true } })
                }, res =>{
                    this.$router.push({ name: 'logged-out', params: { status: 'success', fromRemoveAuth: true } })
                })
        },
        close() {
            this.$emit('close')
        }
    }
}