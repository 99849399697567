import messageService from '../../services/message'
import {handleReqWithPaginatedRes} from '../../helpers'

export default {
	namespaced: true,
	state: {
		inbox_messages: [],
		inbox_messages_meta: null,
		bin_messages: [],
		bin_messages_meta: null,
		conversation: [],
		icebreakers: [],
		notifications: []
	},
	getters: {
		inboxMessages(state) {
			return state.inbox_messages
		},
		inboxMessagesMeta(state){
			return state.inbox_messages_meta
		},
		binMessages(state) {
			return state.bin_messages
		},
		binMessagesMeta(state) {
			return state.bin_messages_meta
		},
		conversation(state) {
			return state.conversation
		},
		icebreakers(state) {
			return state.icebreakers
		},
		notifications(state) {
			return state.notifications
		}
	},
	mutations: {
		setInboxMessages(state, payload) {
			state.inbox_messages = payload
		},
		setBinMessages(state, payload) {
			state.bin_messages = payload
		},
		setInboxMessagesMeta(state, payload) {
			state.inbox_messages_meta = payload
		},
		setBinMessagesMeta(state, payload) {
			state.bin_messages_meta = payload
		},
		setConversation(state, payload) {
			state.conversation = payload
		},
		setIcebreakers(state, payload) {
			state.icebreakers = payload
		},
		addMessageToConversation(state, payload) {
			let conversation = state.conversation;
			conversation.unshift(payload);
			state.conversation = conversation;
		},
		setNotifications(state, payload) {
			state.notifications = payload
		}
	},
	actions: {
		getInboxMessages(context, {params, success_cb}) {
			messageService.getInboxMessages({params})
				.then(response => {
					if ('notifications' in response.data) {
						context.commit('setNotifications', response.data.notifications)
					}
					handleReqWithPaginatedRes(context, {
						response,
						list_mutation: 'setInboxMessages',
						meta_mutation: 'setInboxMessagesMeta',
						success_cb
					})
				})
		},
		deleteMessages(context, {params}) {
			return messageService.deleteMessages({params})
		},
		deleteWelcomeMessage(context) {
			return messageService.deleteWelcomeMessage()
		},
		getBinMessages(context, {params, success_cb}) {
			messageService.getBinMessages({params})
				.then(response => {
					handleReqWithPaginatedRes(context, {
						response,
						list_mutation: 'setBinMessages',
						meta_mutation: 'setBinMessagesMeta',
						success_cb
					})
				})
		},
		getConversation(context, payload) {
			return messageService.getConversation(payload)
				.then(response => {
					context.commit('setConversation', response.data.messages)
				})
		},
		unstickConversation(context, {params, success_cb}) {
			return messageService.unstickConversation(params)
				.then(response => {
					if (typeof success_cb == 'function') success_cb(response)
				})
		},
		sendMessage(context, {nick_name, params}) {
			return messageService.sendMessage({nick_name, params})
		},
		displayMessage(context, params) {
			return new Promise(resolve => {
				let user = context.rootGetters['auth/profile'];
				let newMessage = {
					nick_name: false,
					blur_thumb_picture: user.profile_image_blur,
					thumb_picture: user.profile_image_thumb,
					date: 'now',
					attachment_picture: params.attachment_thumb_path || null,
					message: params.message || params.label,
					sticker_key: params.key || null
				};
				context.commit('addMessageToConversation', newMessage);
				resolve(newMessage);
			})
		},
		getIcebreakers(context, nick_name) {
			return messageService.getIcebreakers()
				.then(response => {
					context.commit('setIcebreakers', response.data.icebreakers)
				})
		},
	}
}
