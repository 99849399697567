export default {
    name: 'subscription-default',
    props: {
        title: {
            type: String,
            default: ''
        },
        mobileNoPad: {
            type: Boolean,
            default: false
        },
        additionalClasses: {
            type: String,
            default: ''
        },
        darkBg: {
            type: Boolean,
            default: true
        },
        hasTopNavBar: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        showDesktop() {
            return this.$root.$children[0].width > 1199
        },
        unreadMessages() {
            let i = this.$store.getters['auth/profile']['welcome_message'] == 1 ? 1 : 0
            let n = parseInt(this.$store.getters['auth/unreadNotifications'])
            return parseInt(this.$store.getters['auth/unreadMessages']) + i + n
        }
    },
}
