<template>
	<div class="base-image" v-images-loaded="imageInstance">
		<img v-if="!loading" :src="transformedImage" :class="`img-responsive ${imgClasses}`" :alt="alt">
    <div class="img-placeholder">

    </div>
		<slot></slot>
	</div>
</template>

<script>
    import BaseImage from '@views/common/BaseImage/base_image'
    import imagesLoaded from 'vue-images-loaded'

    export default {
        mixins: [BaseImage],
        directives: { imagesLoaded },
        methods: {
          imageInstance(instance) {
            console.log(instance)
            instance.elements[0].classList.add(!instance.hasAnyBroken ? 'image-complete' : 'image-broken')
          }
        }
    }
</script>

<style lang="scss" src="./base_image.scss"></style>