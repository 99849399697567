<template>
    <div :class="[!showDesktop ? `con-wrapper` : 'content-wrapper']">
      <template v-if="showDesktop">
        <div class="sub-nav-wrapper con-wrapper">
          <div class="sub-menu-con">
            <div>
              <slot name="sub-menu" />
            </div>
            <button class="btn btn-credits" @click="$router.push({ name: 'user-credits' })">
              <svg width="20" height="20" class="t-icon fire credit-count-icon"></svg>
              <span>{{ 'Get Credits' | translate }}</span>
            </button>
          </div>
        </div>
        <slot name="content" />
      </template>
      <template v-else>
        <div class="content-con mobile" >
          <slot />
        </div>
      </template>
    </div>
</template>

<script>
    export default {
      name: 'DefaultInner',
      props: {
        showDesktop: {
          type: Boolean,
          default: false
        }
      },
      methods: {
        onAppScroll(pos) { this.appScrollPos = pos }
      },
      created() {
        EventBus.$on('appScrolled', this.onAppScroll)
      },
      beforeDestroy() {
        EventBus.$off('appScrolled', this.onAppScroll)
      }
    }
</script>

