<template>
    <div
      :class="['adv-bannr', layoutType, `adv-bannr-${seeking}`, {mobile: !showDesktop, mainstream: isMainstream}]"
    >
        <div class="ad-itm">
            <h6 v-if="layoutType == 'upgraded'" class="title-wrap">
                <span>{{ 'Upgrade to' | translate }}</span> 
                {{ 'Premium' | translate }}
            </h6>
            <h6 v-else class="title-wrap">
              <span class="pr-header-1 ">{{ 'Boost' | translate }} {{ 'your profile' | translate }}</span>
            </h6>
            <p class="pr-subs-1 color-ld-blue text-uppercase">{{ 'And get' | translate }} {{ 'more views' | translate }}</p>
            <button class="btn btn-white">{{ 'upgrade' | translate }}</button>
        </div>
    </div>
</template>

<script>
	import AdvBannr from '@views/user/partials/AdvBannr/adv_bannr'
    import {checkNull} from '@/helpers'

	export default {
        mixins: [AdvBannr],
        computed: {
            seeking() {
                return this.$store.getters['auth/profile']['seeking']
            },
            formValues() {
                return this.$store.getters['pub/formValues']
            },
            isMainstream() {
                if(!checkNull(this.formValues)) return false
                return this.formValues['mainstream']
            }
        }
	}
</script>

<style lang="scss" src="./adv_bannr.scss"></style>
