export const ApnPush = class {
  constructor(web_service_url, website_push_id, user_id) {
    this.web_service_url = web_service_url;
    this.website_push_id = website_push_id;
    this.user_id = user_id;
  }

  checkPermission(cb) {
    if ('safari' in window && 'pushNotification' in window.safari) {
      let permissionData = window.safari.pushNotification.permission(this.website_push_id);
      if (permissionData.permission === 'default') {
        window.safari.pushNotification.requestPermission(
          this.web_service_url,   // The web service URL.
          this.website_push_id,   // The Website Push ID.
          {user_id: String(this.user_id)},  // Data that you choose to send to your server to help you identify the user.
          cb // The callback function.
        );
      }
    }
  }
};

export default {
  ApnPush
}
