export default {
	getTotalOnlineUsers() {
		return Vue.http.get(`api/get-total-of-online-users`,  {
			headers: {
				'Authorization': null
			}
		})
	},
	getProducts() {
		return Vue.http.get(`api/get-products`)
	},
	getPremiumProducts() {
		return Vue.http.get(`api/get-premium-products`)
	},
	getDeletionProduct() {
		return Vue.http.get(`api/get-package/delete`)
	},
	getInactiveUserProduct() {
		return Vue.http.get(`api/get-package/inactive`)
	},
	startPayment({product_id, payment_method_id, params}) {
		let url = `api/start-payment/${product_id}`
		if(payment_method_id) url += `/${payment_method_id}`
		if(params) return Vue.http.get(url, {params})
		return Vue.http.get(url)
	},
	checkPayment(id) {
		return Vue.http.get(`api/check-payment/${id}`)
	},
	getNextPaymentMethod(payload) {
		return Vue.http.post(`api/next-payment-method`, payload)
	},
	validateCardNumber(payload) {
		return Vue.http.post(`api/validate-cc-number`, payload)
	},
	makeCardinityPayment(payload) {
		return Vue.http.post(`api/make-cardinity-payment`, payload)
	},
	idealIssuers(id) {
		return Vue.http.get(`api/ideal-issuers/${id}`)
	},
	allowCancellation() {
		return Vue.http.get(`api/allow-cancellation`)
	},
	cancelSubscription() {
		return Vue.http.get(`api/cancel-subscription/cancel`)
	},
	getSeasonalDiscount() {
		return Vue.http.get(`api/get-seasonal-discount`)
	}
}
