export const subscribeWP = (vapidPublicKey) => {
	return new Promise((resolve, reject) => {
		if (!('serviceWorker' in navigator)) {
			console.log('Service workers aren\'t supported in this browser.')
			reject()
		}

		navigator.serviceWorker.register('/service-worker.js').then(() => {
        }).catch(error => {
			console.log('service worker error', error)
			reject()
		})

		window.Notification.requestPermission().then((permission) => {
			if (permission === "granted") {
				navigator.serviceWorker.ready.then(registration => {
					const options = { userVisibleOnly: true }
					if (vapidPublicKey) options.applicationServerKey = urlBase64ToUint8Array(vapidPublicKey)
					registration.pushManager.subscribe(options)
						.then(sub => {
							resolve(sub)
						})
						.catch(error => {
							console.log('registration pushManager error', error)
							reject()
						})
				})
			}
		});
    })
}

export const unsubscribeWP = () => {
	return new Promise((resolve, reject) => {
		navigator.serviceWorker.ready.then(registration => {
			registration.pushManager.getSubscription().then(subscription => {
				subscription.unsubscribe()
					.then(resolve)
					.catch(reject)
			}).catch(reject)
    	})
    })
}

export const urlBase64ToUint8Array = (base64String) => {
	const padding = '='.repeat((4 - base64String.length % 4) % 4)
	const base64 = (base64String + padding)
		.replace(/\-/g, '+')
		.replace(/_/g, '/')
	const rawData = window.atob(base64)
	const outputArray = new Uint8Array(rawData.length)
	for (let i = 0; i < rawData.length; ++i) {
		outputArray[i] = rawData.charCodeAt(i)
	}
	return outputArray
}

export default {
	subscribeWP,
	unsubscribeWP,
	urlBase64ToUint8Array,
}
