export default {
	getInboxMessages({params}) {
		return Vue.http.get(`api/get-messages/inbox`, {params})
	},
	getBinMessages({params}) {
		return Vue.http.get(`api/get-messages/trash`, {params})
	},
	deleteMessages({params}) {
		return Vue.http.post(`api/delete-messages`, params)
	},
	deleteWelcomeMessage() {
		return Vue.http.get(`api/delete-welcome-message`)
	},
	getConversation({nick_name, params = {}}) {
		return Vue.http.get(`api/get-conversation/${nick_name}`, {params})
	},
	unstickConversation({nick_name, params = {}}) {
		return Vue.http.get(`api/unstick-conversation/${nick_name}`, {params})
	},
	sendMessage({nick_name, params}) {
		return Vue.http.post(`api/send-message/${nick_name}`, params)
	},
	getIcebreakers(nick_name) {
		return Vue.http.get(`api/get-icebreakers`)
	},
}
