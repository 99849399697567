<template>
  <div data-cy="cookies-notification-modal" :class="`cookies-notification-modal ${!showDesktop ? 'mobile' : ''}`">
    <div class="modalinfo">
      <div class="modalcontent">
        <div class="modalwrapper">
          <div class="modaltext">
            <p class="modaltitle">{{ 'We Care About Your Privacy' | translate }}</p>
            <p>
              {{ 'This site uses its own, technical and third party cookies to improve navigation performance, analyzing visitors behaviour to help us and our marketing partners measure campaign results and personalized advertising. By clicking "Accept All" you are agreeing to all of these cookies.' | translate}}
              <router-link :to="{ name: 'cookie-policy' }" class="dark cookies-modal">{{ 'Cookie Policy' | translate }}</router-link>.
            </p>
            <p class="modaltitle">{{ 'We and our partners process data to provide:' | translate }}</p>
            <p>
              {{ 'Use precise geolocation data. Actively scan device characteristics for identification. Store and/or access information on a device. Personalised ads and content, ad and content measurement, audience insights and product development.' | translate }}
              <a href="#partners" class="dark" @click.prevent="openListOfPartners">{{ 'List of partners' | translate }}</a>
            </p>
            <button type="button" class="btn" @click="acceptAllCookies">{{ 'Accept All' | translate }}</button>
            <div class="cookiesset">
              <a id="third-over" @click="openCookiesSettings">{{ 'Cookies Settings' | translate }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import cookies_settings from "@views/common/CookiesSettingsModal/cookies_settings"
  export default {
    name: 'cookies-notification-modal',
    mixins: [cookies_settings]
  }
</script>