<template>
  <div ref="public-l" class="public-layout">
    <slot></slot>
  </div>
</template>

<script>
import BaseMenu from '@theme-views/common/BaseMenu'

  export default { 
    name:'PublicLayout',
    computed: {
        appContainer() {
          return document.querySelector('.app-container')
        },
    },
    mounted() {
      this.appContainer.classList.add('public-l')
    }
  }
</script>

