import matchingService from '../../services/matching'
export default {
	namespaced: true,

	state: {
		swipe_profiles: [],
		swiped_card: null,
		active_profile: null
	},

	getters: {
		swipeProfiles(state) {
			return state.swipe_profiles
		},

		swipedCard(state) {
			return state.swiped_card
		},

		activeProfile(state) {
			return state.active_profile
		}
	},

	mutations: {
		setSwipeProfiles(state, payload) {
			return state.swipe_profiles = payload
		},
		setSwipedCardInfo(state, {data, payload}) {

			var index = state.swipe_profiles.findIndex(o => {
				return o.id === payload.profile_id;
			})

			if (index !== -1) state.swipe_profiles.splice(index, 1)

			console.log(state.swipe_profiles, 'state.swipe_profiles')

			state.swiped_card = data
		},

		setActiveProfile(state, payload) {
			return state.active_profile = payload
		}
	},

	actions: {
		async getSwipeProfiles(ctx) {
			try {
				const { data } = await matchingService.getSwipeProfiles()
				ctx.commit('setSwipeProfiles', data.data)

				return data.data
			} catch (error) {
				console.log(error)
			}
		},

		async interactSwipe(ctx, payload) {
			try {
				const {data} = await matchingService.interactSwipe(payload)
				ctx.commit('setSwipedCardInfo', {
					data,
					payload
				})
								
			} catch (error) {
				console.log(error)
			}
		}
	},
}