import cookies_settings from "@views/common/CookiesSettingsModal/cookies_settings"

export default {
  name: 'cookies-partners-modal',
  mixins: [cookies_settings],
  data() {
    return {
      marketing_selected: true,
      domain_name: window.APP_CONFIG.DOMAIN_NAME
    }
  },
  computed: {
    showDesktop() {
      return this.$root.$children[0].width > 1199
    },
    partners() {
      return this.$store.getters['pub/getMarketingPartners']
    },
    selectedPartners() {
      return (this.partners || []).filter(obj => obj.selected)
    }
  },
  watch: {
    selectedPartners() {
      this.marketing_selected = this.selectedPartners.length === (this.partners || []).length
    },
    marketing_selected() {
      if(!this.marketing_selected && this.selectedPartners.length === this.partners.length) {
        this.partners.map(partner => this.$store.commit('pub/updateMarketingPartner', { id: partner.id, selected: false }))
      } else if(this.marketing_selected && this.selectedPartners.length !== this.partners.length) {
        this.partners.map(partner => this.$store.commit('pub/updateMarketingPartner', { id: partner.id, selected: true }))
      }
    }
  },
  beforeMount() {
    this.$store.dispatch('pub/loadMarketingPartners');
  },
  mounted() {
    this.marketing_selected = this.selectedPartners.length === (this.partners || []).length
  },
  methods: {
    togglePartner(partner) {
      this.$store.commit('pub/updateMarketingPartner', { id: partner.id, selected: !partner.selected })
    },
    toggleDetails(partner) {
      this.$store.commit('pub/updateMarketingPartner', { id: partner.id, show: !partner.show })
    },
    saveSelection() {
      this.cookieConsentSelected.push('advertising')
      this.saveCookiesConsent();
      EventBus.$emit('open-cookies-notification');
    },
    rejectAll() {
      this.cookieConsentSelected = this.cookieConsentSelected.filter(obj => obj !== 'advertising')
      this.saveCookiesConsent();
      EventBus.$emit('open-cookies-notification');
    }
  }
}