export default {
  name: 'main-header',
  data() {
      return {
          site_name: window.APP_CONFIG.DOMAIN_NAME,
          cdn_url: window.APP_CONFIG.CDN_URL,
          icdn_url: window.APP_CONFIG.ICDN_URL,
          open_logout_confirm: false
      }
  },
  computed: {
      credits() {
          return this.$store.getters['auth/credits']
      },
      isAuthenticated() {
          return this.$store.getters['auth/isAuthenticated']
      },
      subscriptionType() {
          return this.$store.getters['auth/subscriptionType']
      },
      gender() {
          return this.$store.getters['auth/profile']['sex']
      }
  },
  mounted() {
      
  }
}