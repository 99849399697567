<template>
    <div class="logout-confirm">
        <div class="heading-wrap">
            <a @click="close" class="heading-close-btn">
                <i class="icon icon-close"></i>
            </a>
            <h4 class="title">{{ 'logout_confirm_title' | translate }}</h4>
        </div>
        <div class="contents">
            <p>{{ 'Are you sure you want to log out?' | translate }}</p>
            <div class="btn-con">
                <div class="btn btn-transparent" @click="confirm">
                    <span>{{ 'Yes' | translate | normalize }}</span>
                </div>
                <div class="btn btn-green" @click="close">
                    <span>{{ 'No' | translate | normalize }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import LogoutConfirm from '@views/common/MainHeader/partials/LogoutConfirm/logout_confirm'

    export default {
        mixins: [LogoutConfirm]
    }
</script>

<style lang="scss" scoped>
.logout-confirm {
    position: absolute;
    z-index: 110;
    top: 100%;
    right: 0;
    width: 360px;
    box-shadow: 0 3px 10px -2px #c9c9c9;
    margin-top: -5px;
    border-radius: 20px;
    overflow: hidden;
    .heading-wrap {
        font-family: $font-primary;
        background: #fff;
        color: var(--sd-secondary-complement);
        padding: 15px 20px;
        width: 100%;
        position: relative;
        h4 {
            max-width: none;
            text-align: left;
            margin: 0;
            font-size: 22px;
            font-weight: 500;
        }
        .title {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            text-align: center;
        }
        .heading-close-btn {
            position: absolute;
            top: 15px;
            left: 12px;
            font-size: 10px;
            color: #fff;
            cursor: pointer;
            i.icon-close {
                width: 12px;
                height: 12px;
            }
        }
    }
    .contents {
        background: #fff;
        padding: 0 20px 15px;
        p {
            text-align: center;
            margin: 0 0 15px;
            font-size: 14px;
        }
    }
    .btn-con {
        display: flex;
        justify-content: center;
        .btn {
            padding: 8px 20px;
            min-width: 80px;
            margin: 0;
            &:nth-child(odd) {
                margin-right: 10px;
            }
            &:nth-child(even) {
                margin-left: 10px;
            }
        }
    }
    .btn-transparent {
        background: transparent;
        color: #232323;
    }
    .btn-green {
        background-image: linear-gradient(to bottom, #3dba19, #008407);
    }
}
</style>
