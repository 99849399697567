import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import * as Cookies from 'js-cookie'

import * as actions from './actions'
import * as getters from './getters'
import * as mutations from './mutations'

import auth from './modules/auth'
import profile from './modules/profile'
import message from './modules/message'
import payment from './modules/payment'
import pub from './modules/pub'
import cookies from './modules/cookies'
import search from './modules/search'
import trial from './modules/trial'
import matching from './modules/matching'

Vue.use(Vuex)

window.Cookies = Cookies
const debug = process.env.NODE_ENV !== 'production'

const store = new Vuex.Store({
	actions,
	getters,
	mutations,
	modules: {
		auth,
		profile,
		message,
		payment,
		pub,
		cookies,
		search,
		trial,
		matching
	},
	strict: debug,
	plugins: [
		createPersistedState({
			paths: ['auth', 'payment.discounts', 'payment.open_discount_popup', 'payment.close_button_discount_popup'],
			storage: {
				getItem: key => Cookies.get(key),
				setItem: (key, value) => Cookies.set(key, value, { expires: 3, secure: !debug, sameSite: 'lax' }),
				removeItem: key => Cookies.remove(key)
			}
		})
	]
})

import vuexI18n from 'vuex-i18n'
Vue.use(vuexI18n.plugin, store)
// initially is loaded only en
Vue.i18n.set('en')
Vue.i18n.fallback('en')

export default store
