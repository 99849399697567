export default {
  namespaced: true,
  state: {
    period: null,
    trial_started: false,
    trial_ended: true,
  },
  getters: {
    getPeriod(state) {
      return state.period
    },
    getTrialStarted(state) {
      return state.trial_started
    },
    getTrialEnded(state) {
      return state.trial_ended
    }
  },
  mutations: {
    setPeriod(state, payload) {
      return state.period = payload
    },
    setTrialStarted(state, payload) {
      return state.trial_started = payload
    },
    setTrialEnded(state, payload) {
      return state.trial_ended = payload
    }
  },
  actions: {
    async startCounter(context, payload) {
      if (payload) {
        context.commit('setPeriod', payload)
      }
      if (context.state.period) {
        while (context.state.period > 0 && context.rootGetters['auth/isAuthenticated']) {
          await new Promise(resolve => setTimeout(resolve, 1000))
          if (context.state.period > 0) {
            context.commit('setPeriod', context.state.period - 1)
          }
        }
      }
    },
    start(context, {token, trial, key, callback}) {
      context.commit('setTrialEnded', false)
      context.commit('setTrialStarted', true)
      context.commit('auth/setTrialKey', key, {root: true});
      context.commit('auth/setAuth', {data: {token}}, {root: true})
      context.dispatch('startCounter', trial).then(res => context.dispatch('end', callback))
      context.dispatch('auth/getUser', {delay_webpush: true}, {root: true})
    },
    end(context, callback) {
      context.commit('setPeriod', null)
      if (context.getters.getTrialStarted && context.getters.getTrialEnded) {
        return;
      }
      if (!context.rootGetters['auth/isOnTrial']) {
        return
      }
      context.commit('setTrialStarted', true)
      context.commit('setTrialEnded', true)
      const profile = context.rootGetters['auth/profile']
      let params = {
        trial_ended: true,
        id: profile.id,
        token: context.rootGetters['auth/trialKey'],
        token_resend: context.rootGetters['auth/trialKey'],
        is_new: true,
        email: profile.email,
        sex_seeking: [profile.sex, profile.seeking].join('-'),
        province_id: profile.province_id,
        country_id: profile.country_id
      }
      return new Promise(resolve => {
        context.dispatch('auth/performLogout', {
          handler: () => {
            callback(params)
          }
        }, {root: true})
      })
    },
    remove(context) {
      context.commit('setTrialStarted', false)
      context.commit('setTrialEnded', true)
      context.commit('setPeriod', null)
    }
  }
}
