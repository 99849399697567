<template>
	<div class="base-menu">
		<ul>
			<li 
				ref="menu_items" 
				v-for="(item, key) in menu" 
				:key="key" 
				v-if="!item.hide"
				@click.stop="handleClickMenu"
			>
				<a :href="item.route" :disabled="item.disabled" :class="[itemClass]"
					v-if="item.isExternal && !item.action_name" target="_blank">
					<i v-if="item.icon_class" :class="item.icon_class">
						<span v-if="item.badge" class="badge">{{ item.badge }}</span>
					</i>
					<span v-if="item.name" class="label" :title="item.title">{{ formatLabel(item.name) }}</span>
				</a>
				<router-link 
					:to="getBasicRoute(item)"
					:disabled="item.disabled" 
					:class="[itemClass]"
					v-if="!item.isExternal && !item.action_name"
				>
					<i v-if="item.icon_class" :class="item.icon_class">
						<span v-if="item.badge" class="badge">{{ item.badge }}</span>
					</i>
					<span 
						v-if="item.name" 
						class="label label--link">{{ formatLabel(item.name) }}
					</span>
					<span 
						v-if="item.sub_title"
						:class="`sub_title ${item.sub_title ? item.sub_title.toString().toLowerCase() : ''} ${item.sub_title_class ? item.sub_title_class : ''}`"
					>
						{{ item.sub_title | translate }}
					</span>
				</router-link>
				<a v-if="item.action_name" :href="item.href || '#'" :disabled="item.disabled" :class="[itemClass]"
					@click.prevent="handleAction(item.action_name, $event, item)">
					<i v-if="item.icon_class" :class="item.icon_class">
						<span v-if="item.badge" class="badge">{{ item.badge }}</span>
					</i>
					<span v-if="item.name" class="label">{{ formatLabel(item.name) }}</span>
				</a>
			</li>
		</ul>
		<slot></slot>
  </div>
</template>

<script>
    import BaseMenu from '@views/common/BaseMenu/base_menu'

    export default {
        mixins: [BaseMenu],
		methods: {
			handleClickMenu() {
				this.$emit('menuClicked')
			}
		}
    }
</script>

<style lang="scss">
.base-menu {
	ul {
		padding: 0px;
		margin: 0px;
		li {
			a[disabled] {
				pointer-events: none;
				cursor: not-allowed;
				color: #B2B2B2;
			}

			span.label {
				display: block;
				width: 100%;
			}
		}
	}
}
</style>
