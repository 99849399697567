<template>
    <div class="profile-sidebar">
        <div class="profile-sidebar-inner">
          <div class="profile-sidebar-photo">
            <div @click.stop="!isMobile ? redirectToPhotos() : null" :class="[isAvatarImageDefault ? 'default-photo' : '', 'avatar-con']">
              <base-image :image="avatarImage" class="img-responsive" alt="avatar image" />
              <svg v-if="isPremium"  width="18" height="18" class="t-icon crown icon-svg"></svg>
              <i v-if="avatarIsPrivate" class="fa fa-unlock-alt"></i>
            </div>
            <div @click.prevent="redirectEditProfile()" class="edit-photo">
              <svg height="24" width="24" class="t-icon edit edit-photo-icon"></svg>
              <span class="edit-photo-label">{{ 'Edit profile' | translate }}</span>
            </div>
          </div>
          <div class="menu-con">
            <base-menu class="main-menu" :menu="main_menu" item-class="df-wrapper"></base-menu>
          </div>
          <div v-if="!isMobile" class="vip-con">
              <h2 class="vip-title df-wrapper" @click="$router.push({ name: 'user-premium' })">
                <svg width="36" height="36" class="t-icon crown icon-svg"></svg>
                <span>{{ 'Vip Members' | translate }}</span>
              </h2>
              <div class="vip-member-list">
                <div v-for="(profile, index) in topProfiles" :key="index" class="vip-member df-wrapper" @click="$router.push({ name: 'user-other-profile', params: { name: profile.nick_name } })">
                  <div class="vip-avatar">
                    <base-image :image="profile.thumb_picture" class="img-responsive" alt="VIP Member" />
                  </div>
                  <div class="vip-info">
                    <div class="name-container">
                      <h5 class="page-header-2 color-gray">{{profile.nick_name}}</h5>
                      <svg width="14" height="14" class="t-icon crown icon-svg"></svg>
                    </div>
                    <div class="location-container">
                      <svg width="16" height="16" class="t-icon pin icon-pin"></svg>
                      <span class="location form-header-1 color-gray-lighter">{{profile.province_name}}</span>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
        <div class="ad-con" v-if="!isPremium && !isMobile">
          <adv-bannr @click.native="$router.push({ name: 'user-premium' })"></adv-bannr>
        </div>
    </div>
</template>

<script>
    import BaseMenu from '@theme-views/common/BaseMenu'
    import AdvBannr from '@theme-views/user/partials/AdvBannr'
    import ProfileSidebar from '@views/user/partials/ProfileSidebar/profile_sidebar'
    import BaseImage from '@theme-views/common/BaseImage'

    delete ProfileSidebar.mounted
    delete ProfileSidebar.created
    delete ProfileSidebar.methods.populateCredits
    delete ProfileSidebar.watch.credits
    delete ProfileSidebar.watch.gender

    export default {
        props: {
          isMobile: {
            type: Boolean,
            default: false
          }
        },
        data() {
            return {
                default_avatar_male: `${window.APP_CONFIG.CDN_URL}/images/male.png`,
                default_avatar_female: `${window.APP_CONFIG.CDN_URL}/images/female.png`,
                main_menu: [
                    { name: 'Home', route: 'user-home', svg_icon: { name: 'home', width: 24, height: 24}, test_id:'sb-home-dt' },
                    { name: 'Messages', route: 'user-messages', svg_icon: { name: 'envelope', width: 24, height: 20}, sub_title: null, test_id:'sb-messages-dt'},
                    { name: 'Visits', route: 'user-visits', svg_icon: { name: 'eye', width: 24, height: 24}, test_id:'sb-visits-dt'},
                    { name: 'Search', route: 'user-search-profiles', svg_icon: { name: 'magnifying-glass', width: 24, height: 24}, test_id:'sb-search-dt'},
                    { name: 'Favorites', route: 'user-favorites', svg_icon: { name: 'star', width: 24, height: 24}, test_id:'sb-favorites-dt'},
                    { name: 'Likes', dontTranslate: true, route: 'user-other-favorites', svg_icon: { name: 'stacked-heart', width: 24, height: 24}, test_id:'sb-likes-dt'}
                ],
                cdn_url: window.APP_CONFIG.CDN_URL
            }
        },
        methods: {
            ...ProfileSidebar.methods,
            logout() {
                this.$store.dispatch('auth/logout')
                    .then(res => {
                        this.$router.push({ name: 'logged-out', params: { status: 'success', fromRemoveAuth: true } })
                    }, res =>{
                        this.$router.push({ name: 'logged-out', params: { status: 'success', fromRemoveAuth: true } })
                    })
            },
            redirectToPhotos() {
                if(this.$route.name == 'user-profile') {
                    let profile_comp = this.$root.$children[0].$children.find(val => {
                      return val.$el.className == 'user-profile'
                    })
                    if(profile_comp) profile_comp.openTab( profile_comp.tabs[1] )
                } else {
                    this.$router.push({ name: 'user-photos' })
                }
            },
            redirectEditProfile() {
              this.$router.push({ name: 'user-profile' })
            }
        },
        computed: {
            ...ProfileSidebar.computed,
            topProfiles() {
                let profiles = []
                if(this.$store.getters['profile/profiles'].length) {
                    this.$store.getters['profile/profiles'].forEach((val, key) => {
                        if(profiles.length < 3) {
                            profiles.push(val)
                        }
                    })
                }

                return profiles
            }
        },
        watch: {
            ...ProfileSidebar.watch,
            webcam_url: {
                handler(v) {
                    const index = this.main_menu.findIndex(e => e.name === "Cams");
                    if(v) {
                        if (index === -1) {
                            this.main_menu.push({ name: 'Cams', svg_icon: { name: 'cams', width: 24, height: 24 }, action_name: 'redirectWebCamUrl', href: '/cams', test_id:'sb-cams-dt' })
                        }
                    } else if(index !== -1) {
                        this.$delete(this.main_menu, index);
                    }
                },
                immediate: true
            }
        },
        mounted() {
            this.populateUnreadMessage(this.unreadMessages)
        },
        components: {
            BaseMenu,
            AdvBannr,
            BaseImage
        }
    }
</script>

<style lang="scss" src="./profile_sidebar.scss"></style>
