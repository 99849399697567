<template>
    <div class="main-footer ">
        <div class="wrapper">
            <div class="con-wrapper">
                <div class="row">
                    <div v-if="showDesktop" class="col-xs-12 logo-con footer-wrapper">
                        <router-link :to="{ name: 'home' }">
                            <img class="img-responsive" :src="`${icdn_url}/desktop-logo.png`" :alt="`Logo ${site_name}`" v-cloak>
                        </router-link>
                        <p v-html="footerText" />
                      </div>
                    <div v-if="sub_menu_1.length" class="col-xs-12 info-con">
                        <base-menu
                            :class="`${sub_menu_1.length <= 2 ? 'centered': 'start'} sub-menu main-footer--links-con`" 
                            :menu="sub_menu_1"
                        />
                    </div>
                    <div class="col-xs-12">
                        <div class="cp-txt-con">
                            <div class="company-cc">
                                <div v-if="showDesktop" class="gateway-icons">
                                    <img class="visa" :src="`${cdn_url}/images/footer-icons/visa-white.png`" alt="visa" />
                                    <img class="mastercard" :src="`${cdn_url}/images/footer-icons/mastercard.png`" alt="mastercard" />
                                </div>
                                <p>
                                    Copyright © {{ current_year }} <b>{{ site_name }}</b> {{ 'All rights reserved.' | translate }}
                                </p>
                                <div v-if="!hide_address && showDesktop" class="address-icon long-address">
                                    <img class="address" :src="footer_address" alt="address" />
                                </div>
                            </div>
                            
                            <base-menu 
                                v-if="sub_menu_2.length > 0" 
                                class="sub-menu main-footer--links-sub" 
                                :menu="sub_menu_2" 
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div v-if="city_pages.length" class="wrapper below">
            <div class="con-wrapper">
                <div class="row">
                    <div class="city-page-link col-xs-12 col-sm-6 col-md-3" v-for="page in city_pages" :key="page.id">
                        <a :href="`${origin}/${page.url}`">{{ limitLinkText(page.title) }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import MainFooter from '@views/common/MainFooter/main_footer'
    import { checkNull } from '@/helpers'

    delete MainFooter.data
    delete MainFooter.created
    
    export default {
        mixins: [MainFooter],
        data() {
            return {
                icdn_url: window.APP_CONFIG.ICDN_URL,
                cdn_url: window.APP_CONFIG.CDN_URL,
                links: window.APP_CONFIG.LINKS,
                content: window.APP_CONFIG.LANDING_PAGE_CONTENT,
                site_name: window.APP_CONFIG.DOMAIN_NAME,
                hide_address: window.APP_CONFIG.HIDE_ADDRESS,
                footer_address: window.APP_CONFIG.FOOTER_ADDRESS,
                sub_menu_1: [
                    { name: 'Support', route: 'support' },
                    { name: 'Blog', route: 'home' },
                    { name: 'Affiliates', route: window.APP_CONFIG.AFFILIATE_LINK, isExternal: true},
                ],
                sub_menu_2: [
                    { 
                        name: 'Cookies Policy', 
                        route: 'cookie-policy',
                        idx: 3
                    },
                    { 
                        name: 'Privacy', 
                        route: this.$t('privacy'), 
                        isBasic: true,
                        idx: 4
                    },
                    { 
                        name: 'FAQ', 
                        route: 'faq',
                        idx: 5
                    },
                    { 
                        name: 'Terms and Conditions', 
                        route: this.$t('terms-and-conditions'), 
                        isBasic: true,
                        idx: 9
                    },
                ],
                current_year: (new Date()).getFullYear(),
                origin: window.location.origin,
            }
        },
        computed: {
            showDesktop() {
                return this.$root.$children[0].width > 1199
            },
            routeName() {
                return this.$route.name
            }
        },
        methods: {
            init_links_mobile() {
                this.sub_menu_1 = []
                this.sub_menu_2.push({ 
                    name: 'Support', 
                    route: 'support', 
                    idx: 0
                })

                if(!Array.isArray(this.links)) {
                    return;
                }

                this.links.forEach(val => {
                    if(val.name) {
                        switch(val.name) {
                            case 'blog': 
                                this.sub_menu_2.push({ 
                                    name: 'Blog', 
                                    route: val.url , 
                                    isExternal: true, 
                                    hide: checkNull(val.hide),
                                    idx: 1
                                }); 

                                break;
                            case 'affiliates': 
                                this.sub_menu_2.push({
                                    name: 'Affiliates', 
                                    route: window.APP_CONFIG.AFFILIATE_LINK || val.url , isExternal: true, hide: checkNull(val.hide),
                                    idx: 2
                                }); 

                                break;
                            case 'about-us': 
                                this.sub_menu_2.push({ 
                                    name: 'About Us', 
                                    route: val.url, 
                                    isBasic: true, 
                                    hide: checkNull(val.hide),
                                    idx: 6
                                }); 

                                break;
                            case 'opinions': 
                                this.sub_menu_2.push({ 
                                    name: 'Opinions', 
                                    route: val.url, 
                                    isBasic: true, 
                                    hide: checkNull(val.hide) ,
                                    idx: 7
                                }); 
                                    
                                    break;
                            case 'us-notice':
                                this.sub_menu_2.push({ 
                                    name: val.label || '18 U.S.C. 2257', 
                                    route: val.url, 
                                    isExternal: true, 
                                    hide: false, 
                                    title: val.title,
                                    idx: 100
                                });
                                
                                break;

                            default: let item = { name: val.name, route: val.url, isBasic: true };
                                if(val.isExternal) {
                                    item.isExternal = true
                                    item.isBasic = false
                                }
                                this.sub_menu_2.push(item)
                                break;
                        }
                    }
                })

                return this.sub_menu_2.sort((i, x) => {
                    return i.idx - x.idx 
                })
            }
        },
        mounted() {
            this.showDesktop ? this.init_links() : this.init_links_mobile()
            this.check_footer_image()
        }
    }
</script>

<style lang="scss" src="./main_footer.scss"></style>
