<template>
    <div :class="`mobile-menu ${opened ? 'opened' : ''}`" @click.stop="handleCloseSidebar">
        <div :class="[{ 'landscaped': isLandscape },'mm-wrap animate__animated']" @click.stop="">
          <div class="notif-header">
            <h3 class="header-title pr-header-3">
              <svg @click.prevent.stop="closeMenu" class="t-icon times" width="20" height="20"></svg>
              {{ 'Notifications' | translate }}
            </h3>
            <div class="credit-btn">
              <div class="credit-count">
                <div name="credit-count" class="credit-count-content">
                  <svg width="24" height="24" class="t-icon fire credit-count-icon"></svg>
                  <span class="credit-count-credits color-red page-header-2">
                    {{ credits.toLocaleString() }}
                  </span>
                </div>
              </div>
              <button class="btn btn-round credits-btn" @click.stop="goToCreditsPage">
                <i class="t-icon lg plus svg-icon" />
              </button>
            </div>
          </div>
          <!-- <profile-sidebar is-mobile /> -->
          <notification :is-opened="opened" :notifications="recentActivity" />
        </div>
    </div>
</template>

<script>
    import MainFooter from '@views/common/MainFooter/main_footer'
    import ProfileSidebar from '@theme-views/user/partials/ProfileSidebar'
    import Notification from '@theme-views/user/partials/Notification'

    export default {
        mixins: [MainFooter],
        name: 'mobile-menu',
        components: {
          ProfileSidebar,
          Notification
       },
        data() {
            return {
              icdn_url: window.APP_CONFIG.ICDN_URL,
              cdn_url: window.APP_CONFIG.CDN_URL,
              site_name: window.APP_CONFIG.DOMAIN_NAME,
              opened: false
            }
        },
        props: {
            initOpened: {
                type: Boolean,
                default: false
            },
            isLandscape: {
              type: Boolean,
              default: false
            }
        },
        methods: {
            openMenu() {
                if(this.opened) {
                    this.$root.$children[0].$refs['app-con'].classList.remove('modal-open')
                } else {
                    this.$root.$children[0].$refs['app-con'].classList.add('modal-open')
                }
                this.opened = !this.opened
            },
            closeMenu() {
                this.$root.$children[0].$refs['app-con'].classList.remove('modal-open')
                this.opened = false
            },
            handleCloseSidebar() {
              this.closeMenu()
              EventBus.$emit('close-sidebar')
            },
            handleOpenSidebar() {
              this.opened = true
            },
            goToCreditsPage() {
              this.$router.push({ name: 'user-credits' })
              this.handleCloseSidebar()
            }
        },
        computed: {
            menu() {
                return this.sub_menu_1.concat( this.sub_menu_2 )
            },
            gender() {
                return this.$store.getters['auth/profile']['sex']
            },
            credits() {
                return this.$store.getters['auth/credits']
            },
            premiumDaysRemaining() {
                return this.$store.getters['auth/premiumDaysRemaining']
            },
            recentActivity() {
                return this.$store.getters['profile/recentActivity']
            }
        },
        created() {
            this.opened = this.initOpened
            EventBus.$on('open-sidebar', this.handleOpenSidebar)
            EventBus.$on('close-sidebar', this.closeMenu)
        },
        beforeDestroy() {
            EventBus.$off('open-sidebar', this.handleOpenSidebar)
            EventBus.$off('close-sidebar', this.closeMenu)
            this.$root.$children[0].addHtmlClass(false, 'body', 'no-scroll')
        },
        watch: {
          opened(newVal) {
            this.$emit('sstate', newVal)
            if (newVal) {
              this.$root.$children[0].$refs['app-con'].classList.add('sidebar-active')
              this.$root.$children[0].addHtmlClass(true, 'body', 'no-scroll')
            } else {
              this.$root.$children[0].$refs['app-con'].classList.remove('sidebar-active')
              this.$root.$children[0].addHtmlClass(false, 'body', 'no-scroll')
            }
          },
          isLandscape(newVal) {
            console.log(newVal)
          }
        }
    }
</script>

<style lang="scss" src="./mobile_menu.scss"></style>
