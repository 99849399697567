import Vue from 'vue'
import router, {defaultRoutes} from '@/routes'
import enTranslations from './en'

Vue.i18n.add('en', enTranslations)

function translateRoutes(routes) {
  return routes.map(function (route) {
    if (route.path) {
      route.path = Vue.i18n.translate(route.path)
    }
    if (route.children) {
      route.children = translateRoutes(routes.children)
    }
    return route;
  })
}

function setI18nLanguage(lang) {
  Vue.i18n.set(lang)

  // translate all route paths
  let routes = translateRoutes(defaultRoutes)

  // @todo this works only once. need a solution to update the routes
  router.addRoutes(routes);
  return lang
}

const requireContext = require.context('../locale', false, /.*\.js$/);

const translations = requireContext.keys()
  .filter(file => file !== 'index.js')
  .map(file => file.replace(/(^.\/)|(\.js$)/g, ''))

export function loadTranslations(lang) {

  console.log('loadTranslations: ', lang)
  console.log( Vue.i18n.localeExists(lang), translations.indexOf(lang) !== -1, `@store/locale/${lang}.js` )
  if (Vue.i18n.localeExists(lang)) {
    return setI18nLanguage(lang)
  }
  if (translations.indexOf(lang) !== -1) {
    return import(/* webpackChunkName: "app/locale/[request]" */ `@store/locale/${lang}.js`).then(
      translations => {
        console.log(lang, translations)
        Vue.i18n.add(lang, translations.default)
        return setI18nLanguage(lang)
      }
    )
  } else {
    console.error(`missing translation for ${lang}`)
    return setI18nLanguage(lang)
  }
}
