<template>
  <div class="notification-center">
    <div ref="notif-list" class="list-wrapper">
      <ul v-if="notifications.length" class="notification-center-list">
        <li
          v-for="(item, index) in notifications"
          :key="index"
          class="notification-center-item"
        >
          <notification-card
            :local_url="cdn_url"
            :notification="item"
            @on-click="notifRedirect(item)"
          />
        </li>
      </ul>
      <div v-else class="info-msg-con">
       {{ 'No Notifications' | translate }}
      </div>
    </div>
  </div>
</template>

<script>
  import NotificationCard from './NotificationCard'

  export default {
    name: 'Notification',
    components: { NotificationCard },
    props: {
      notifications: {
        type: Array,
        default: () => []
      },
      isOpened: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        cdn_url: window.APP_CONFIG.CDN_URL
      }
    },
    computed: {
      unread_notifications() {
        return this.$store.getters['auth/unreadNotifications']
      }
    },
    methods: {
      redirectToSearch() {
        this.$router.push({ name: 'user-search-profiles' })
        EventBus.$emit('close-sidebar')
      },
      notifRedirect(item) {
        // todo: redirect
        EventBus.$emit('close-sidebar')
        this.$store.dispatch('auth/markAsReadUpdates', { id: item.id })
          .then(res => {
            this.$store.commit('auth/setUnreadNotifications', this.unread_notifications - 1)
          }, err => {
            console.log(err)
          })
        this.$router.push({ name: 'user-other-profile', params: { name: item.nick_name} })
      }
    },
    watch: {
      isOpened(newVal) {
        if(newVal)
        this.$refs['notif-list'].scrollTop = 0
      }
    }
  }
</script>

<style lang="scss" src="./notification.scss">