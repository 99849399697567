<template>
  <a
    :class="[{'unread': unread}, 'notification-center-card']"
    @click.stop="onClick"
  >
    <div class="image-wrapper">
      <base-image class="prof-pic" :image="notification.thumb_picture"
      :blur-image="withBlur"></base-image>
    </div>
    <div class="detail">
      <p class="detail-message form-header-1">
        {{ notification.text }}
      </p>
      <p class="detail-time form-header-1">
        <svg width="10" height="10" class="t-icon time icon-svg d-inline-block"></svg>
        {{ notification.date }}
      </p>
    </div>

    <div class="dots-wrapper">
      <!-- <a href="#" class="dots-menu" /> -->
    </div>
  </a>
</template>

<script>
  import BaseImage from '@theme-views/common/BaseImage'

  export default {
    name: 'NotificationCard',
    components: { BaseImage },
    props: {
      notification: {
        type: Object,
        default: () => {}
      },
      local_url: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        unread: false
      }
    },
    methods: {
      onClick() {
        this.$emit('on-click')
        this.unread = false
      }
    },
    mounted() {
      this.unread = this.notification.unread
    },
    computed: {
      withBlur() {
        return !Boolean(this.$store.getters['auth/profile']['remove_blur']) && Boolean(this.notification.blur_thumb_picture)
      }
    }
  }
</script>

<style lang="scss">
.notification-center-card {
  .image-placeholder {
    width: 60px;
    height: 60px;
    p {
      display: none;
    }
  }
}
</style>