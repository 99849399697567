
export default {
    data() {
        return {
            completeRegisterTimeoutIndex: null,
            boostProfileTimeoutIndex: null,
            boostPremiumEndTimeoutIndex: null,
        }
    },
    computed: {
        profile() {
            return this.$store.getters['auth/profile'];
        },
    },
    methods: {
        checkCompletedRegisterUser() {
            if (["user-activate-profile", 'complete-register'].includes(this.$route.name || '')) {
                return;
            }

            if (!this.profile || this.profile.delay_complete_register_suggestion === -1) {
                this.completeRegisterTimeoutIndex && clearTimeout(this.completeRegisterTimeoutIndex);
                this.completeRegisterTimeoutIndex = null;
                return;
            }

            if (this.completeRegisterTimeoutIndex) {
                return;
            }

            const open = (delay) => {
                this.completeRegisterTimeoutIndex = setTimeout(() => {
                        this.openCompleteRegister()
                    },
                    delay
                )
            }
            let delay = this.profile.delay_complete_register_suggestion;
            if (delay < 5) {
                delay = 5;
            }
            if (this.modal.open) {
                this.closeModal();
            }
            open(delay * 1000);
        },
        checkBoostProfileUser() {
            if (["user-activate-profile", 'boost-profile'].includes(this.$route.name || '')) {
                return;
            }

            if (!this.profile || this.profile.delay_boost_profile_suggestion === -1 ) {
                this.boostProfileTimeoutIndex && clearTimeout(this.boostProfileTimeoutIndex);
                this.boostProfileTimeoutIndex = null;
                return;
            }

            if (this.profile.has_scheduled_delete) {
                return;
            }

            if (this.boostProfileTimeoutIndex) {
                return;
            }

            const open = (delay) => {
                this.boostProfileTimeoutIndex = setTimeout(() => {
                        this.openBoostAccount()
                    },
                    delay
                )
            }
            let delay = this.profile.delay_boost_profile_suggestion;

            if (delay < 5) {
                delay = 5;
            }
            if (this.modal.open) {
                this.closeModal();
            }
            open(delay * 1000);
        },
        checkBoostPremiumEnd() {
            if (this.profile.delay_boost_premium_end === -1) {
                return;
            }

            if (this.boostPremiumEndTimeoutIndex) {
                return;
            }

            setTimeout(() => {
                this.$store.dispatch('auth/getUser');
            }, (this.profile.delay_boost_premium_end + 10) * 1000)
        }
    },
    watch: {
        profile: {
            handler(v, o) {
                if (v && `${v.id}`.length) {
                    this.checkCompletedRegisterUser();
                    this.checkBoostProfileUser();
                    this.checkBoostPremiumEnd();
                } else if(o && `${o.id}`.length) {
                    this.completeRegisterTimeoutIndex && clearTimeout(this.completeRegisterTimeoutIndex);
                    this.boostProfileTimeoutIndex && clearTimeout(this.boostProfileTimeoutIndex);
                }
            },
            deep: true,
            immediate: true
        },
        '$route.name'(toName, fromName) {
            if (fromName === "user-activate-profile" && this.profile && `${this.profile.id}`.length) {
                this.checkCompletedRegisterUser();
                this.checkBoostProfileUser();
                this.checkBoostPremiumEnd();
            }
        }
    }
}
