import {checkNull, safeRedirect} from '@/helpers'

export default {
    name: 'adv-bannr',
    data() {
        return {
            cdn_url: window.APP_CONFIG.CDN_URL,
            banner_interval: null,
            ibs: 0 //index banner shown
        }
    },
    props: {
        layoutType: {
            type: String,
            default: 'default'
        },
        banners: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    methods: {
        setMultiBanner(interval) {
            this.ibs = 0
            clearInterval(this.banner_interval)
            this.banner_interval = setInterval(() => {
                if(checkNull(this.banners[this.ibs+1])) {
                    this.ibs ++
                } else {
                    this.ibs = 0
                }
            }, interval)
        },
        clickToRedirect(link) {
            safeRedirect(link)
        }
    },
    computed: {
        showDesktop() {
            return this.$root.$children[0].width > 1199
        }
    },
    beforeDestroy() {
        clearInterval(this.banner_interval)
    },
    mounted() {
        if(this.banners.length > 1) this.setMultiBanner(15000)
    }
}
