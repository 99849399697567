<template>
  <div :class="`cookies-settings-modal ${!showDesktop ? 'mobile' : ''}`">
    <div class="modalinfo">
      <div class="modalcontent">
        <div class="modalwrapper">
          <div class="modaltext">
            <p class="modaltitle">{{ 'About your privacy' | translate }}</p>
            <p>
              <span>{{ 'We use cookies for traffic balancing, session identification, analytics and marketing attribution. You can opt-in or opt-out of any or all of them from this panel.' | translate }}</span>
              <span v-html="translationWithLink()"></span>
            </p>
            <button type="button" class="btn acceptallbutton" @click="acceptAllCookies">{{ 'Accept All' | translate | normalize(3) }}</button>
            <p class="modaltitle">{{ 'Manage Consent Preferences' | translate }}</p>
            <ol class="switches">
              <li class="showcontent" v-for="consent in cookieConsentAll" :key="consent.key">
                <span class="switchtext" @click="toggleDescription(consent)" >
                  <em class="showtext" v-if="consent.show_description">-</em>
                  <em class="hiddetext" v-else>+</em>
                  {{ consent.label | translate }}
                </span>
                <input :id="`consent-${consent.key}`" type="checkbox" v-model="cookieConsentSelected" :value="consent.key" :disabled="consent.key === 'technical' ? 'disabled': false">
                <label :for="`consent-${consent.key}`">
                  <span></span>
                </label>
                <p class="switchtextshowed" v-if="consent.show_description">{{ consent.description | translate }}</p>
              </li>
            </ol>
            <div class="buttonconfirm">
              <button type="button" class="btn selectionbutton" @click="saveSelection">{{ 'Save Selection' | translate | normalize(3) }}</button>
            </div>
            <div class="buttonrefuse">
              <button type="button" class="btn rejectallbutton" @click="rejectAllCookies">{{ 'Reject All' | translate | normalize(3) }}</button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  import cookies_settings from "@views/common/CookiesSettingsModal/cookies_settings"
  export default {
    name: 'cookies-settings-modal',
    mixins: [cookies_settings]
  }
</script>
