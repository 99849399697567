export default {
	"Already a member?": "Allerede medlem?",
	"Login": "Log ind",
	"Log out": "Log ud",
	"Your current status": "Nuværende status",
	"BASIC": "BASIC",
	"Basic": "Basic",
	"Bronze": "Bronze",
	"Elite": "Elite",
	"VIP": "VIP",
	"Join Us": "Tilmeld",
	"Find people near you": "Find andre nær dig",
	"Join now": "Tilmeld dig gratis",
	"I am a": "Jeg er en",
	"Email": "Email",
	"It will not be published": "Det vil ikke blive publiseret",
	"E-mail address": "E-mail adresse",
	"SEARCH NOW": "SØG NU",
	"I confirm that I am over 18 years old": "Jeg bekræfter at jeg er over 18 år",
	"Do not just think about it! Discover the hottest dating site in Australia. Every day thousands of people like you join us.": "Tænk ikke kun på det! Opdag den hotteste datingside i Danmark. Tusinder af nye profiler hver dag.",
	"Information": "Information",
	"All rights reserved.": "Alle rettigheder forbeholdes.",
	"SUBSCRIPTION": "OPRETTELSE",
	"Other": "Andet",
	"All fields are required": "Alle felter skal udfyldes",
	"The fields marked in Red are required. Please fill them to continue.": "Felterne markeret med rød er påkrævet. Venligst udfyld disse for at fortsætte.",
	"If you did not find a suitable answer please contact us": "Fandt du ikke svar på dit spørgsmål, så kontakt os venligst",
	"Will help us to identify you": "Vil hjælpe os med at identificere dig",
	"Invalid Username": "Ugyldigt brugernavn",
	"Subject": "Emne",
	"Tell us how can we help you": "Fortæl os hvordan vi kan hjælpe dig",
	"Please enter subject": "Udfyld venligst emne",
	"Please enter at least 10 characters": "Skal indeholde minimum 10 tegn",
	"Connected to your account": "Forbundet med din konto",
	"Repeat Email": "Gentag email",
	"Emails did not match": "Forkert email",
	"Message": "Besked",
	"No Recent Activity": "Ingen nylig aktivitet",

	//route labels
	"Home": "Hjem",
	"Contact": "Kontakt",
	"Support": "Hjælp",
	"Affiliates": "Søsterselskaber",
	"Privacy": "Privatliv",
	"Terms": "Betingelser",

	//routes uri
	"/login": "/log-ind",
	"/contact": "/kontakt",
	"/support": "/hjalp",
	"/affiliates": "/sosterselskab",
	"/privacy": "/privatliv",
	"/terms-and-conditions": "/generelle-vilkar-og-betingelser",
	"/cookie-policy": "/cookiepolitik",
	"/subscription/exit": "/oprettelse/exit",
	"/purchase-confirmation": "/kob-bekraftelse",
	"/page/:page_name": "/side/:page_name",

	"/dashboard": "/hjem",
	"/mobile-dashboard": "/mobil-forside",
	"/activate-profile/:token": "/aktiver-profil/:token",
	"/edit-profile": "/rediger",
	"/profile/:name": "/profil/:name",
	"/my-picture": "/mit-billede",
	"/messages/inbox": "/beskeder/indbakke",
	"/messages/trash": "/beskeder/papirkurv",
	"/messages/send/:name": "/beskeder/send/:name",
	"/visitor": "/besogende",
	"/favorite/my": "/favorit/mig",
	"/favorite/others": "/favorit/andre",
	"/search": "/sog",
	"/search-result": "/soge-resultat",
	"/settings": "/indstillinger",
	"/validate-new-email-address/:token": "/bekraft-ny-email-adresse/:token",
	"/subscription": "/oprettelse",
	"/credits": "/credits",
	"/elite": "/elite",
	"/faq": "/faq",
	"/delete-account": "/slet-konto",

	"/exclusive-promotion": "/eksklusiv-promotion",
	"/account-deleted": "/konto-slettet",
	"/logged-out": "/logget-ud",
    "/complete-register/:token" : '/fuld-registrering/:token',

	//login
	"Login": "Log ind",
	"Enter username or Email": "Indtast brugernavn eller Email",
	"Enter Password": "Indtast adgangskode",
	"Username": "Brugernavn",
	"Nickname": "Kælenavn",
	"Password": "Adgangskode",
	"I forgot my password": "Glemt adgangskode",
	"Password Reminder": "Hjælp til adgangskode",
	"Enter you email": "Indtast email",
	"Your email address": "Din email adresse",
	"Don't have an account?": "Har du ingen konto?",
	"REGISTER": "TILMELD",
	"Password sent to your email": "Adgangskode er send til din email",
	"This account has not been validated yet": "Denne konto er ikke blevet aktiveret",
	"LOGIN USING YOUR CREDENTIALS": "BRUG DINE OPLYSNINGER FOR AT LOGGE IND",
	"FORGOT YOUR PASSWORD?": "GLEMT ADGANGSKODE?",
	"Login failed": "Kunne ikke logge ind",
	"Username or password is incorrect": "Forkert brugernavn eller kodeord",

	"We're sorry!": "Vi beklager",
	//registration
	"You're almost done!": "Du er der næsten!",
	"Please fill your additional account information": "Venligst udfyld yderligerer kontooplysninger",
	"Just one step left!": "Kun et trin tilbage!",
	"Let us learn more about you. Fill in your personal data": "Fortæl os mere om dig. Udfyld dine personlige data",
	"Activate your account now": "Aktiver din konto nu",
	"Activate your account now.": "Aktiver din konto nu.",
	"Please confirm your e-mail address": "Venligst bekræft din e-mail adresse",
	"Please fill in the following form to join {site_name} for free": "Udfyld formlen for at få gratis adgang til {site_name}",
	"CONTINUE": "FORTSÆT",

	"By clicking on the continue button I agree with the": "Ved at klikke på \"fortsæt\" accepterer du vores ",
	"terms": "vilkår",
	"and cookies": "og cookies",

	"This site is protected by reCaptcha and the Google": "Denne side er beskyttet af reCaptcha og the Google",
	"Privacy Policy": "Privatlivspolitik",
	"and": "og",
	"Terms of Service": "Vilkår for Brug",
	"apply.": "ansøg.",

	"Personal description": "Personlig beskrivelse",
	"optional": "valgfrit",
	"FINALIZE": "FÆRDIGGØR",
	"We have just sent you a confirmation e-mail at": "Vi har sendt dig en bekræftelses-e-mail til",
	"To start using our website, please click on the activation link in this email.": "For at begynde at bruge vores hjemmeside, venligst klik på aktiverings linket i denne email.",
	"Click here to verify your email": "Klik her for at bekræfte din email",
	"CLICK TO ACTIVATE": "KLIK FOR AT AKTIVERE",
	"CLICK TO ACTIVATE - desktop": "KLIK FOR AT AKTIVERE",
	"VERIFY MY EMAIL": "BEKRÆFT MIN MAIL",
	"Not received any email yet?": "Ikke modtaget mailen?",
	"Please check your": "Venligst tjek din",
	"spam": "uønsket mail",
	"box and wait at least 10 minutes or re-send activation": "boks og vent 10 min eller send aktiveringen på ny.",
	"Enter Nickname": "Indtast kælenavn",
	"Enter Your Name": "Indtast navn",
	"Password Required": "Adgangskode påkrævet",
	"Enter valid Email": "Indtast gyldig email",
	"Please enter at least 5 characters": "Venligst indtast minimum 5 tegn",
	"Enter strong password": "Indtast stærk adgangskode",
	"Email not available": "Ugyldig email",
	"Nickname not available": "Kælenavnet er optaget",
	"Maximum 4 number allowed": "Maximum 4 numre tilladt",
	"Name should not contain any space, number or special character": "Navn kan ikke indeholde mellemrum, nummer eller specielle tegn",
	"Nickname should not contain any space or special characters": "Kælenavn kan ikke indeholde mellemrum eller specielle tegn",
	"Your activation mail has been sent": "Din aktiverings mail er blevet sendt",
	"Complete your profile in a minute and start contacting people": "Færdiggør din profil på 1 minut og begynd at møde andre profiler",
	"Complete your profile": "Opret din profil",
	"Create Profile": "Opret profil",
	"Your description": "Din beskrivelse",
	"Confirm your Email": "Bekræft  Email",
	"We have sent you an email.": "Vi har sendt dig en email.",
	"Check your email to complete your registration": "Tjek din email for at færdiggøre din registrering",
	"and be able to speak with other users.": "og begynd at snakke med andre brugere.",
	"If you can not find it look it up in the spam or": "Hvis du ikke kan finde den, tjek din uønsket mail eller",
	"If you have not received it select one of the following options": "Har du ikke modtaget den prøv en af de følgende muligheder",
	"junk folder": "Uønsket mail",
	"Change Email": "Skift Email",
	"Resend Email": "Gensend Email",
	"Resend": "Gensend",
	"New Email": "Ny Email",
	"Your activation key is invalid": "Din aktiveringskode er ugyldig",
	"Please include an '@' in the email address. '{email}' doesn't have an '@'": "Venligst indsæt et '@' i mailadressen. '{email}' mangler '@'",

	//public pages
	"privacy": "privatliv",
	"terms-and-conditions": "vilkår-og-betingelser",
	"about-us": "om-os",

	//forms
	"Name": "Navn",
	"Marital Status": "Civilstatus",
	"Date of Birth": "Fødselsdato",
	"Country": "Land",
	"Location": "Beliggenhed",
	"Province": "Område",
	"City": "By",
	"Save Changes": "Gem ændringer",
	"Select from list": "Vælg fra liste",
	"Day": "Dag",
	"Month": "Måned",
	"month": "måned",
	"Year": "År",
	"Prefer not to say": "Det holder jeg for mig selv",
	"Only profiles with photo": "Kun profiler med billede",
	"This field is required": "Skal udfyldes",
	"Please update your profile": "Opdater din profil",
	"Profile successfully updated": "Profil opdateret",
	"The fields marked in red are required. Please fill them to continue.": "Felterne markeret med rød er påkrævet. Venligst udfyld disse for at fortsætte.",
	"Characters left": "Tegn tilbage",
	"Image attached": "Billede vedhæftet",
	"The message field is required.": "Dette beskedfelt er påkrævet.",
	"WITH PICTURE": "MED BILLEDE",
	"all age groups": "alle aldre",
	"EDIT DESCRIPTION": "REDIGER BESKRIVELSE",
	"DESCRIPTION": "BESKRIVELSE",
	"FEATURING": "FEATURING",
	"Please Select": "Vælg ",
	"Emails do not match": "Ugyldig email",
	"Your request was succesfully sent!": "Din anmodning er sendt!",
	"Or send us a message directly": "Eller send os en direkte besked",

	//user pages
	"My Profile": "Min profil",
	"Add Photos": "Tilføj billeder",
	"Messages": "Beskeder",
	"Favorites": "Favoritter",
	"Search": "Søg",
	"Settings": "Indstillinger",
	"Visits": "Besøgende",
	"Likes": "Likes",
	"Membership": "Medlemskaber",
	"Vip Members": "VIP Medlemmer",
	"Profile_mobile_menu": "Profil",

	"Account activation successful": "Aktivering af konto udført",
	"My chats": "Mine beskeder",

	//user home
	"Quick Search": "Hurtig søg",
	"More Options": "Mere",
	"I am a": "Jeg er en",
	"Looking for": "Leder efter",
	"Age": "Alder",
	"Search for": "Søg",
	"All age groups": "Alle aldre",
	"Popular Members": "Populære Medlemmer",
	"New Members": "Nye Medlemmer",
	"Find a Match": "Find et Match",
	"Recent Activity": "Seneste aktivitet",
	"Add Photo": "Tilføj Billede",
	"Back": "Tilbage",
	"Become Elite": "Bliv Elite",
	"Upgrade": "Opgrader",

	"likes": "likes",
	"EDIT MY PROFILE": "REDIGER MIN PROFIL",
	"CONFIGURATION": "INDSTILLLINGER",
	"ONLINE SUPPORT": "ONLINE HJÆLP",

	//user message
	"Trash": "Papirkurv",
	"Delete Selected": "Slet Valgte",
	"No Messages Available": "Ingen beskeder tilgængelige",
	"No Messages selected": "Ingen beskeder valgt",
	"Back to inbox": "Tilbage til indbakke",
	"Back to messages": "Tilbage til beskeder",
	"Select all": "Vælg alt",
	"Deselect all": "Fravælg alt",
	"Next Page": "Næste side",
	"Prev Page": "Forrige side",
	"Type your message here": "Skriv din besked her",
	"Send message": "Send besked",
	"Send Icebreaker": "Send isbryder",
	"VIEW PROFILE": "SE PROFIL",
	"DELETE": "SLET",
	"Chat with": "Skriv med",
	"Chat": "Chat",

	//user favorites
	"My Favorites": "Mine favoritter",
	"No likes": "Ingen likes",
	"Favorite added successfully": "Favorit tilføjet",
	"Favorite deleted successfully": "Favorit slettet",
	"Visit Profile": "Besøg profil",

	//user visits
	"My Profile Visitors": "Mine besøgende",
	"No Visitors": "Ingen besøgende",

	//user photos
	"My pictures": "Mine billeder",
	"See my public profile": "Se min offentlige profil",
	"Profile photo": "Profilbillede",
	"Profile Picture": "Profilbillede",
	"2MB or lower.": "2MB eller mindre.",
	"2MB or lower": "2MB eller mindre",
	"Nudes not allowed in this category.": "Nøgenbilleder er ikke tilladt i denne kategori.",
	"PHOTO ALBUM": "ALBUM",
	"Allowed images 2 megabytes or less": "Billeder på 2 megabytes eller mindre er tilladt",
	"Add picture": "Tilføj billede",
	"Main reasons for rejection": "Hovedårsager til afvisninger",
	"Are you sure?": "Er du sikker?",
	"Are you sure you want to delete this image?": "Er du sikker på du vil slette dette billede?",
	"Close": "Luk",
	"Confirm": "Bekræft",
	"Attaching image": "Vedhæft billede",
	"The message must be at least 2 characters.": "Beskeden må være på mindst 2 tegn.",
	"Deleting...": "Sletter...",
	"Pictures of underage people": "Billeder af mindreårige",
	"Personal data is visible": "Personlig data er synlig",
	"Fake or stolen pictures": "Falske eller stjålne billeder",
	"Different gender to profile": "Andet køn end profil",
	"Group pictures": "Gruppe billeder",
	"Weapons, drugs, violence": "Våben, euforiserende stoffer, vold",
	"No people visible": "Ingen synlige personer",
	"Info": "Info",
	"We are reviewing your images. When they pass the verification process they will be shown to the other users.": "Vi gennemgår dine billeder. Når de består vores verifikationsproces vil de kunne ses af andre brugere.",

	//user profiles
	"Profile": "Profil",
	"yrs": "år",
	"years": "år",
	"yr from": "år fra",
	"Physique": "Fysik",
	"Photos": "Billeder",
	"No Photos": "Ingen billeder",
	"About Me": "Om mig",
	"Characteristics": "Egenskaber",
	"Prefers": "Foretrækker",
	"Relationship": "Forhold",
	"Shape": "Form",

	//user edit profile
	"DESCRIBE YOURSELF": "BESKRIV DIG SELV",
	"GENERAL": "GENERELT",
	"(Will be verified)": "(vil blive verificeret)",
	"Sex": "Køn",
	"Seeking": "Søger",
	"Finish your profile": "Færdiggør din profil",
	"Welcome! As your profile is new, we will give maximum visibility once you complete it.": "Velkommen! Siden din profil er ny, vil vi give dig maks synlighed når du har færdiggjort din profil.",

	//search profile
	"Advanced Search": "Avanceret Søgning",
	"OPEN TO": "ÅBEN FOR",
	"PREFERENCES": "PRÆFERENCER",
	"CHARACTER": "KARAKTER",
	"Search Result": "Søge Resultat",
	"No Member found": "Ingen Medlemmer Fundet",
	"Change Search": "Skift Søgning",
	"Hair Color": "Hårfarve",
	"Eye Color": "Øjenfarve",
	"Body Type": "Kropstype",
	"Height": "Højde",
	"Weight": "Vægt",
	"Smoker": "Ryger",
	"Ethnicity": "Etnicitet",
	"From": "Fra",
	"To": "Til",

	//settings
	"Change E-mail address": "Skift E-mail Adresse",
	"Your email address has been successfully updated": "Din email adresse er opdateret",
	"Password": "Adgangskode",
	"Account Status": "Konto Status",
	"Cancel": "Fortryd",
	"Delete Account": "Slet Konto",
	"Notifications": "Notifikationer",
	"Receive all notifications individually": "Modtag alle notifikationer individuelt",
	"Receive one daily email with all my notifications": "Modtag en daglig mail med alle mine notifikationer",
	"Receive a weekly email with all my notifications": "Modtag en ugentlig mail med alle mine notifikationer",
	"I don\'t want to receive any notifications": "Jeg ønsker ikke at modtage notifikationer",
	"(we will send you a verification email)": "(Vi sender dig en aktiveringsmail)",
	"Current Password": "Nuværende Adgangskode",
	"New Password": "Ny Adgangskode",
	"Enter valid email": "Indtast gyldig email",
	"Repeat New Password": "Gentag Ny Adgangskode",
	"Save Settings": "Gem ændringer",
	"Passwords should match": "Adgangskoderne skat matche",
	"Password length should be minimum 5 characters": "Adgangskodens længde skal være på minimum 5 tegn",
	"Attention: Your new email address must be verified by clicking on the link that we just sent you.": "Varsel: Din nye email adresse skal aktiveres ved at klikke på linket vi har sendt dig.",
	"I want to receive notifications by email": "Jeg vil gerne modtage meddelser via email",
	"Status": "Status",
	"Renewal": "Fornyelse",
	"Auto renewal Canceled": "Automatisk Fornyelse Annulleret ",
	"Delete profile": "Slet Profil",
	"Your account has been deleted. You will be redirected.": "Din konto er blevet slettet. Du vil blive omdirigeret.",
	"Successfully updated": "Succesfuldt opdateret",
	"Current account status": "Nuværende konto status",
	"Are you sure you want to cancel your subscription?": "Er du sikker på at du vil annullere dit tilkøb?",
	"Your subscription has been successfully canceled and will not be auto-renewed": "Dit tilkøb er blevet annulleret og vil ikke blive automatisk fornyet",
	"Due to technical reasons, cancellation of the renewal of the trial must be made after 8 hours of the acquisition. For any other subscription, the cancellation must be done at least 8 hours after acquisition and before 48 hours of its expiration.": "På grund af tekniske problemer, Annullering af fornyelse af prøveperioden må foretages efter 8 timer efter erhvervelsen. For andre typer tilkøb, Annulleringen må foretages efter 8 timer efter erhvervelsen og før 48 timer før dets udløbstidspunkt.",
	"Profile deleted. Redirecting...": "Profil slettet. Omdirigerer...",
	"*Active subscriptions deleted this way will not be reimbursed": "*Aktive tilkøb annulleret på denne måde vil ikke kunne refunderes",
	"Are you sure you want to delete * your account? Your information will be permanently erased.": "Er du sikker på du vil slette* din konto? Al information vil blive permanent slettet.",
	"Settings successfully updated": "Indstillinger er blevet opdateret",
	"Disable Notification": "Deaktiver meddelse",
	"The notifications have been successfully disabled.": "Meddelserne er blevet deaktiveret.",
	"Canceled Auto-Renewal": "Auto-Fornylelse Annulleret",

	//subscription
	"Only Vip members can contact more than 5 different users per day.": "Kun VIP medlemmer kan kontakte mere end 5 medlemmer om dagen.",
	"Upgrade your status": "Opgrader din status",
	"Enjoy our exclusive benefits and make new contacts": "Nyd vores esklusive fordele og få nye kontakter",
	"Users Online": "Brugere er online",
	"Discreet Billing. {site_name} will not appear in the invoice": "Diskret fakturering. {site_name} vises ikke på fakturaen.",
	"Month ::: Months": "Måned ::: Måneder",
	"3 days trial": "3 dages prøve",
	"Try 3 days": "Prøv 3 dage",
	"MEMBERSHIP": "MEDLEMSKAB",
	"Select": "vælg",
	"Send messages for 3 consecutive days": "Send beskeder i 3 dage i træk",
	"Only one trial subscription per user": "Kun en prøveperiode pr bruger",
	"In order to offer better service without interruptions, all plans will be auto renewed at the cost of one of the following subscriptions based on the pack chosen above.": "For at kunne tilbyde bedre service uden afbrydelser, alle planer fornyes automatisk til bekostning af en af følgende abonnementer baseret på den valgte pakke ovenfor.",
	"for": "for",
	"For full access to all details of events, including how to cancel it,": "For fuld adgang til alle detaljer af events, inklusiv hvordan du aflyser ,",
	"click here": "klik her",
	"for terms and conditions,": "for vilkår og betingelser,",
	"All profiles are reviewed and moderated by our team.": "Alle profiler bliver gennemset og modereret af vores team.",
	"CURRENT PLAN": "MIDLERTIDIG PLAN",
	"Basic (no trial bonus) You do not have any active pack at this moment and you are a BASIC user. To increase your chances of connecting with and contacting other users, you will need to upgrade to Elite or VIP status.": "Basic (ingen prøvebonus) Du har ingen aktiv pakke for øjeblikket and du er BASIC bruger. For at øge dine chancer for at få kontakt til andre brugere må du opgradere til Elite eller VIP status.",
	"MY BENEFITS": "MINE FORDELE",
	"{discount} cheaper": "{discount} billigere",
	"save": "gem",
	"Create my profile": "Lav min profil",
	"Read messages": "Læs beskeder",
	"Add to Favorites": "Føj til Favoritter",
	"Make yourself visible to other users by adding them to favorites": "Gør dig synlig for andre brugere ved at tilføje dem som favoritter",
	"Photo Album": "Fotoalbum",
	"No fake pictures allowed": "Falske billeder er ikke tilladt",
	"Album pictures": "Album billeder",
	"Show yourself and increase your possibilities": "Gør dig synlig og forøg dine muligheder",
	"Detailed profile search": "Detaljeret profil søg",
	"Find exactly what you are looking for": "Find præcis hvad du søger",
	"See other user's pics": "Se andre brugeres billeder",
	"Choose your favorite": "Vælg din favorit",
	"Reply to and send messages": "Svar til, og send beskeder",
	"Make direct contact with up to five users per day": "Få direkte kontakt med op til 5 brugere pr dag",
	"Be shown first in searches": "Bliv vist øverst i søgninger",
	"Your profile will be featured before the rest of the users": "Din profil vil blive vist før andre brugeres",
	"Make direct contact with unlimited users": "Få direkte kontakt med ubegrænset antal brugere",
	"Choose payment method": "Vælg betalingsmetode",
	"LESS THAN": "MINDRE END",
	"PER DAY": "OM DAGEN",
	"DISCRETION": "DISKRETION",
	"Our website name will not be disclosed": "Navnet på vores webside vil ikke blive offentliggjort",
	"Our name will not be disclosed": "Hjemmesidens navn fremgår ikke på fakturaen",
	"100% Anonymous.": "100% Anonymt.",
	"Choose your subscription": "Vælg dit abonnement",
	"Recommended": "Anbefalet",
	"Most Popular": "Mest populære",
	"Secure payments with": "Sikker betaling med",
	"Your message has NOT been sent. You need to upgrade your status in order to send messages.": "Din besked er IKKE blevet sendt. Du skal opgradere din status for at kunne sende flere beskeder.",
	"Your purchase has been successful": "Dit køb var vellykket",
	"All subscriptions automatically renew to ensure optimum service. If you want to change the settings you can do it": "Alle abonnementer bliver automatisk fornyet for at give dig den bedste service. Vil du ændre dette kan du gøre det",
	"here.": "her.",
	"after_here": "",

	//ad
	"Become a": "Bliv",
	"PREMIUM MEMBER": "PREMIUM MEDLEM",
	"And contact": "Tilføj kontakt ",
	"more people": "flere personer",

	"upgrade": "opgrader",
	"Boost": "Boost",
	"boost": "Boost",
	"your profile": "din profil",
	"Start chatting and meet more people": "Begynd at chat og møde flere mennesker",
	"And get": "og få",
	"more views": "flere besøg",

	"Now +3 extra Credits with each purchase": "Nu +3 ekstra credits ved ethvert køb",
	"Best Seller": "Bedst sælgende",
	"Select your Package": "Vælg din pakke",
	"Less than {amount} per credit": "Mindre end {amount} pr credit",
	"Less than": "Mindre end",
	"per credit": "pr credit",
	"Free credits": "Gratis credits",
	"per purchase": "pr køb",
	"Premium Status doesn’t allow free messages.": "Premium status tillader ikke gratis beskder.",
	"Chat now": "Chat nu",
	"Get Credits": "Få Credits",
	"Credit": "Credit",
	"Credits": "Credits",
	"My credits": "Mine Credits",
	"{count} Credits left": "{count} Credits tilbage",
	"Your message has not been sent, you need credits to be able to send messages.": "Dine beskeder er ikke blevet sendt, du skal have credit for at kunne sende beskeder.",
	"Icebreaker": "Isbryder",
	"Icebreaker today": "Isbryder i dag",
	"The cost for sending an Icebreaker is 2 credits. The Icebreaker can only be used to start a conversation or in response to another icebreaker.": "1 isbryder koster 2 credits. Isbrydere kan kun bruges til at starte en samtale eller til at svare på en anden isbryder.",
	"With the acquisition of each package, users make a purchase of a pack of credits that can be used to send messages. The ratio of credits per message is ten to one, that means, the user needs to have at least ten credits in his account to send a message. The cost for sending an Icebreaker is 2 credits.": "Pakker med credits bliver ikke automatisk fornyet. Brugeren foretager et køb af en pakke credits, der kan bruges til at sende beskeder. Forholdet mellem credit pr besked er 10 til 1. Dette betyder at brugeren skal have mindst 10 credits på sin konto for at sende en besked.",

	"Trial pack": "Prøve pakke",
	"Only one trial pack per user allowed": "Kun en prøvepakke pr bruger tilladt",
	"Used": "Brugt",

	"Exclusive Promotion": "Eksklusiv Promotion",
	"Account Deleted": "Konto Slettet",
	"Log out Successful": "Du er nu logget ud",

	"Do you like FREE LIVE SEX?": "Kan du lide GRATIS LIVE SEX?",
	"Enter this code to enjoy a 10% discount:": "Nyd en 10% Rabat ved brug af dennen kode:",
	"Discount redeemable for your first online purchase. Expires in 2 hours.": "Denne kode er til dit første online køb, og udløber om 6 timer.",

	"This account has been deleted.": "Denne konto er slettet.",
	"Our algorithm has found a better option in line with your taste.": "Vores algoritme har fundet et bedre alternativ i tråd med din smag.",
	"You will be redirected in a few seconds.": "Du bliver videresendt om få sekunder.",

	"Has added you to favorites": "Har tilføjet dig til sine favoritter",
	"Is looking at your profile": "Kigger på din profil",
	"See profile": "Se profil",
	"See message": "Se besked",

	"I think I am in love": "Jeg tror jeg er forelsket",
	"Hey! I'm winking at you": "Hej! Jeg blinker til dig",
	"Tell me more about you. Fancy a chat?": "Fortæl mig mere om dig selv. Lyst til en snak?",
	"Some flowers for you!": "Blomster til dig!",
	"Sending you a kiss": "Sender dig et kys",
	"Surprise, it\'s me!": "Surprise, Det mig!",
	"Hi, how are you?": "Hi, Hvordan går det?",
	"Let's get naughty": "Lad os være frække",

	"Get 5 times the regular response rate. Premium members benefits:": "Få 5 gange den normale svarrate. Fordele ved premiummedlemmer:",
	"Appear high up on searches": "Bliv vist højt i søgefeltet",
	"Distinctive badge that increases your visibility": "Særligt badge, der øger din synlighed",
	"5 Free Icebreakers a day": "5 isbryderer om dagen",
	"In addition to this, a voucher for a 15% Webcam discount": "I tillæg til, en rabatkupon på 15% Webcam rabat",

	"The Icebreaker can only be used to start a conversation or in response to another icebreaker.": "Isbrydere koster 2 credits og kan bruges til at starte samtaler, eller til at svare på en anden isbryder.",

	"Succesful purchase": "Succesfuldt køb",
	"Your purchase has been successful.": "Dit køb var succesfuldt.",
	"It can take a few minutes to update.": "Det kan tage et par minutter at opdatere.",
	"You will be redirected.": "Du vil blive omdirigeret.",

	"Changes can take some minutes to update.": "Ændringer kan tage et par minutter at opdatere.",

	"Yearly Package": "Årspakke",
	"Package": "Pakke",
	"Buy Now": "Køb Nu",
	"The Elite status allows you to contact up to 5 different users every day by sending them unlimited messages.": "Elite status tillader dig at kontakte op til 5 forskellige brugere hver dag ved at sende dem ubegrænsede beskeder.",

	"Exclusive offer": "Eksklusivt tilbud",
	"Welcome": "Velkommen",
	"Mary from {domain_name} support": "Mary fra {domain_name} support",
	"Welcome to {domain_name}. We hope you find what you are looking for.": "Velkommen til {domain_name}. Vi håber du finder hvad du søger.",
	"Here are some Pro Tips to be more successful and meet people:": "Her er der nogle Pro Tips for at blive mere succesfuld og møde flere mennesker:",
	"Upload a profile picture to get more connections.": "Upload et profilbillede for at få flere henvendelser.",
	"Send an icebreaker to get a conversation easily started.": "Send en isbryder for lettere at starte en samtale.",
	"If you like someone don’t forget to add them to favorites so they will know.": "Hvis du finder nogen du kan lide, så glem ikke at tilføj personen til dine favoritter, så de kan se det.",
	"And of course, if you like somebody send a message and ask for a date!": "Og selvfølgelig, hvis du finder nogen du kan lide, så send dem en besked og inviter dem ud på en date!",
	"If you need any extra help, please contact our ": "Har du behov for ekstra hjælp, så kontakt vores ",
	"support": "support",
	". We will be happy to help you.": ". Vi vil med glæde hjælpe dig. ",
	"Try your luck": "Prøv lykken",
	"64% of our users have a date in the first 2 weeks.": "64% af vores brugere har en date indenfor de første 2 uger.",
	"Good Luck!.": "Held og Lykke!",
	"*This message will be deleted upon closing.": "*Denne besked vil blive slettet ved lukning.",

	"Private picture": "Privat billede",
	"Private pictures will appear blurred out. Only users that have already paid will be able to see them.": "Private billeder vil blive slørret. Kun brugere der allerede har betalt vil kunne se disse billeder.",
	"Name change": "Ændre navn",
	"If you want to change your name or nickname, please contact our support team and we will do it within 24h.": "Hvis du vil ændre dit navn eller Kælenavn, venligst kontakt vores support team og vi vil ændre det indenfor 24 timer.",
	"See all images": "Se alle billeder",
	"Unlock all private photos forever for just 20 credits": "Lås op for alle private billeder for altid, for kun 20 credits",
	"Unlock forever all private pictures": "Lås op for alle private billeder for altid",

	"This email address is not registered, please sign up to log in.": "Denne email adresse er ikke registreret, venligst tilmeld dig for at logge ind.",
	"Congratulations, your account is now active.": "Tillykke, din konto er nu aktiv.",
	"Complete one last step and have fun!": "Færdiggør et sidste skridt og start det sjove!",
	"OR": "ELLER",

	"We sent you an email with a link to activate your account valid for the next 12 hours to:": "Vi har sendt dig en email med et link til at aktivere din konto, linket er aktivt de næste 12 timer for:",
	"If you haven\'t received it in your inbox or ": "Hvis du ikke har modtaget den i din indbakke, eller",
	"or you want to modify the email address, click on the button below.": "eller du vil modificere din email adresse, klik nedenfor.",
	"We sent you an email to verify your account, the email will be valid for the next 12 hours:": "Vi har sendt dig en e-mail for at aktivere din konto, mailen vil være aktiv de næste 12 timer:",
	"If you can\'t find it in your inbox or spam, you can edit or resend it": "Hvis du ikke finder den i din indbakke eller uønsket mail box, kan du redigere eller gensende den",
	"Resend or change email": "Gensend eller ændre mail",
	"Please use the same email you registered.": "Venligst brug den samme email som du har registreret.",
	"Connect with Google": "Forbind med Google",
	"Connect with Yahoo": "Sign up with Yahoo",
	"Connect with Microsoft": "Forbind med Hotmail",
	"Or turn back": "Go back",
	"Complete your registration": "Complete your registration",

	"It will not be shared": "Den vil ikke blive delt",
	"It will never be shared. 100% Privacy": "Den vil aldrig blive delt med andre. 100% privat",

	"Unlock all private pictures forever.": "Lås alle private billeder op permanent.",
	"Unlock": "Lås op",

	"You will be redirected in a second": "Du vil straks blive omdirigeret",

	"Card payment": "Card payment",
	"Bank transfer": "Bank transfer",
	"Prepaid card": "Prepaid card",

	"Error": "Fejl",
	"page not found": "Siden blev ikke fundet",
	"Back to homepage": "Tilbage til hjemmeside",

	"per month": "pr. måned",
	"week": "uge",
	"{count} week": "{count} uge",

	"Your profile has been blocked": "Din profil er blevet blokeret",
	"In order to offer the best possible user experience, we block all profiles that haven\'t sent a message within 21 days of registration.": "For at vi kan tilbyde den bedst mulige service til alle vores brugere, blokerer vi profiler der ikke har sendt en besked i mere end 21 dage.",
	"Please acquire a credit package to unlock your profile and restore all its features.": "For at låse din profil op og få adgang til alle funktioner på siden, skal du tilkøbe en creditpakke.",
	"Restore access to:": "Gendan adgang til:",
	"All messages": "Alle beskeder",
	"Users search": "Bruger søgning",
	"Your contacts": "Dine kontakter",
	"All favourites": "Alle favoritter",
	"All pictures": "Alle billeder",
	"Send Icebreakers": "Send isbryder",
	"Unblock Profile": "Lås profilen op",

	"This website uses its own and third-party cookies and identifiers to improve accessibility and analyse our traffic. If you continue browsing, we consider that you accept its use. For more information visit our": "Dette websted bruger sine egne og tredjeparts cookies og identifikatorer for at forbedre tilgængeligheden og til at analysere vores trafik. Hvis du fortsætter med at browse, konkludere vi, at du accepterer brugen af disse. For mere information, se vores",
	"Cookies Policy": "Cookies-politik",

	"DATE GUARANTEE": "DATE GARANTI",
	"Take advantage of our Date guarantee": "Gør brug af vores date garanti",
	"No date? Money Back!": "Ingen date, pengene tilbage!",
	"No risk": "Ingen risiko",
	"Guarantee of success": "Garanti for succes",
	"Terms and Conditions": "Vilkår og betingelser",

	"Boost your visibility": "Øg din synlighed",
	"SAVE": "SPAR",
	"Add 3 months premium": "Tilføj 3 måneders premium",
	"Add {months} months premium": "Tilføj {months} måneders premium",

	"We guarantee you a real date.": "Vi garanterer dig en ægte date, hvilket betyder følgende.",
	"YES, I WANT A DATE": "JA, JEG ØNSKER EN DATE",

	"Terms and conditions of the date guarantee. In order to fulfill all refund conditions, you must have a fully completed profile for a period of at least six months and your personal description in your profile. Your profile must contain at least one approved picture throughout this period. Furthermore, each week, you must contact at least seven different members located close to you (radius of 100km) within your age range (-5/+5 years). If you fulfill all the conditions listed above and you still were not able to find a date, we will full refund all the payments you made during this time. This guarantee is only valid for the first 12 months following your registration. Good luck!": "Vilkår og betingelser for vores date garanti. For at opfylde alle betingelserne for refundering, skal du have en fuldt udfyldt og aktiv profil med en personlig beskrivelse, i en periode på mindst 6 måneder. Desuden skal du hver uge kontakte mindst syv nye forskellige medlemmer i dit nær område (radius på 100 km) indenfor din aldersgruppe (-5/+5 år). Hvis du opfylder alle ovennævnte betingelser og du stadig ikke har kunnet finde en date, refunderer vi alle betalinger du har foretaget i løbet af denne periode. Denne garanti er kun gyldig i 12 måneder efter din registrering. Held og lykke!",

	"You have {count} days premium left": "Du har {count} dage tilbage på ditt premium abonnement",
	"Check our special offer before deleting your account!": "Tjek vores specialtilbud, før du sletter din konto!",
	"Delete my account": "Slet min konto",
	"Free": "Gratis",

	"Premium, Elite and VIP packages are auto-renewable, which means they will auto-renew when they expire.": "Premium, Elite og VIP pakker kan automatisk fornyes, hvilket betyder at de automatisk fornyes ved udløbet.",
	"and can confirm that I am 18 years or older.": "og jeg bekræfter, at jeg er over 18 år.",

	"COMPLETE YOUR PURCHASE": "FÆRDIGGØR DIT KØB",
	"Payment details": "Betalingsoplysninger",
	"Total": "Total",
	"3-digit number on the back of your credit card": "3-cifret nummer på bagsiden af dit kreditkort",
	"MM / YY": "MM / ÅÅ",
	"MM": "MM",
	"YY": "ÅÅ",
	"Name on card": "Navn på  kortholder",
	"Charges will appear discreetly as tpmpsup.com": "Ændringerne vil blive vist diskret som virtualcharges.com",
	"Buy Now": "Køb Nu",
	"Premium package 3 Months": "Premium pakke 3 måneder",

	"SPECIAL OFFER": "SÆRTILBUD",
	"Unlock your account with our special offer": "Lås din konto op med dette særtilbud",
	"Take advantage of our special offer to unlock your profile and restore all its features.": "Udnyt vores særtilbud til at låse din profil op og gendanne alle dens funktioner.",

	"This purchase was declined. Please check your details and try again.": "Dit køb mislykkedes. Tjek venligst at dine oplysninger er korrekte og prøv igen.",
	"Your purchase was succesful.": "Dit køb er gennemført.",

	"card_number_require": "Kortnummer er påkrævet",
	"card_number_invalid": "Kortnummeret er ugyldigt",
	"card_name_require": "Kortholders navn er påkrævet ",
	"card_name_invalid": "Kortholders navn er ugyldigt",
	"card_cvc_require": "CVC er påkrævet",
	"card_cvc_invalid": "CVC er ugyldigt",
	"card_expiry_require": "Kortets udløbsdato er påkrævet",
	"card_expiry_invalid": "Kortets udløbsdato er ugyldigt",

	"Private photo": "Privat billede",
	"Search for someone now": "Søg efter nogen nu",
	"Edit": "Rediger",

	"Locked Album": "Låst album",
	"Get credits to unlock all albums": "Få credits for at åbne albummet",
	"picture ::: pictures": "billede ::: billeder",
	"Open": "Åben",

	"Receive notifications": "Aktivér notifikationer",
	"I want to be notified when I receive a message": "Jeg vil have besked, når jeg modtager en ny besked",
	"No": "Nej",
	"Yes": "Ja",

	"logout_confirm_title": "Log ud",
	"Are you sure you want to log out?": "Er du sikker på at du vil logge ud?",

	"Your payment has been cancelled": "Din betaling er annulleret",
	"Something went wrong, please try again in 10 minutes": "Noget gik galt. Prøv igen om 10 minutter",

	"Please accept the terms and conditions to continue": "Accepter venligst vilkårene og betingelserne for at fortsætte",
	"By checking this box and clicking continue I accept the": "Ved at markere dette felt og klikke på fortsæt accepterer jeg",
	"rg_terms": "Vilkår og Betingelserne",
	"rg_privacy": "Privatpolitik",
	"rg_cookies": "Cookies",
	"I am aware of the use of fictitious profiles on the website and I confirm that I am of legal age.": "Jeg er opmærksom på brugen af fiktive profiler på hjemmesiden, og jeg bekræfter, at jeg er myndig.",

	"Cookie": "Cookie",
	"Responsible Party": "Ansvarlig part",
	"Category": "Kategori",
	"Cookie Name": "Navn",
	"Purpose": "Formål",
	"Lifespan": "Levetid",
	"Type of Cookie": "Type af cookie",
	"Strictly necessary": "Strengt nødvendigt",
	"Functional": "Funktionel",
	"Performance": "Ydeevne",
	"Advertising": "Advertising",
	"cookie-policy": "cookiepolitik",
	"cookie policy": "politik side for cookies",

	"We Care About Your Privacy": "Vi bekymrer os om dit privatliv",
	"This site uses its own, technical and third party cookies to improve navigation performance, analyzing visitors behaviour to help us and our marketing partners measure campaign results and personalized advertising. By clicking \"Accept All\" you are agreeing to all of these cookies.": "Dette websted bruger egne, tekniske og tredjepartscookies til at forbedre navigationsydeevnen, analysere de besøgendes adfærd og hjælpe os og vores marketingspartnere med at måle kampagneresultaterne og personlig reklame. Ved at klikke på \"Accepter alle\" accepterer du alle disse cookies.",
	"Accept All": "Accepter alle",
	"Cookies Settings": "Cookies indstillinger",
	"About your privacy": "Om dine personlige oplysninger",
	"We use cookies for traffic balancing, session identification, analytics and marketing attribution. You can opt-in or opt-out of any or all of them from this panel.": "Vi bruger cookies til at justere trafikken, sessionsidentifikation, analyse og markedsføringstildeling. Du kan tilmelde dig eller fravælge et eller flere af dem fra dette panel.",
	"You will be able to change your set up at any time from our :cookies_policy page": "Du vil til enhver tid kunne ændre din opsætning fra vores :cookies_policy",
	"Accept all": "Accepter alle",
	"Manage Consent Preferences": "Administrer indstillinger for samtykke",
	"Technical (Mandatory)": "Teknisk (Obligatorisk)",
	"Performance (Monitor website performance)": "Ydeevne (Overvåg webstedsydelse)",
	"Functional (User experience improvement)": "Funktionel (Forbedring af brugeroplevelsen)",
	"Advertising (Marketing measurement)": "Annoncering (Marketingsmåling)",
    "Advertising (Personalization)": "Reklame (Tilpasning)",
	"Save Selection": "Gem valg",
	"Reject All": "Afvis alle",

	"We and our partners process data to provide:": "Vi og vores partnere behandler data for at levere:",
	"Use precise geolocation data. Actively scan device characteristics for identification. Store and/or access information on a device. Personalised ads and content, ad and content measurement, audience insights and product development.": "Brug præcise geolokaliseringsdata. Scan aktivt enhedens egenskaber for identifikation. Gemme og/eller få adgang til oplysninger på en enhed. Tilpassede annoncer og indhold, annonce- og indholdsmåling, indblik i målgrupper og produktudvikling.",
	"List of partners": "Liste over partnere",
	"Cookie Policy": "Cookie politik",
	"These cookies are used in connection with the tracking of actions related to advertisements. For example, these cookies will remember that you have visited {name} and can be used to show you a personalized advertisement on another website. Please check the following links of the platforms where we promote ourselves, Google, Microsoft and Twitter. for more information.": "Disse cookies bruges i forbindelse med sporing af handlinger relateret til reklamer. For eksempel vil disse cookies huske, at du har besøgt {name} og kan bruges til at vise dig en personlig annonce på et andet websted. Tjek venligst følgende links til de platforme, hvor vi promoverer os selv, Google, Microsoft og Twitter.",
	"Delete cookies": "Cookies",
	"Save selection": "Gem valg",
	"Reject all": "Afvis alle",

	"{name} hasn't replied to you yet. Send another message!": "{name} har ikke svaret dig endnu. Skriv en besked mere.",
	"Validate my Ice-breaker": "Validér min isbryder",
	"now": "nu",

	"Starting  June 1, 2023 onwards, we will not process payments with PaySafeCard.": "Fra 1. Juni 2023 og fremadrettet, vil vi ikke behandle betalinger med PaySafeCard.",

	"Dear User,": "Kære bruger,",
	"Thanks for your interest in joining our website, but, in order to keep a gender balance close to 50/50 men and women, registration is not allowed for men, at the moment.": "Tak for din interesse i at tilmelde dig vores hjemmeside, men for at holde en kønsbalance tæt på 50/50 mænd og kvinder, er registrering ikke tilladt for mænd i øjeblikket.",
	"If you still want a date, click on the button below and try one of the best websites online.": "Hvis du stadig ønsker en date, så klik på knappen nedenfor og prøv en af de bedste hjemmesider online.",
	"Try Now": "Prøv nu",

	"Your account has been deleted.": "Din konto er blevet slettet.",
	"According to your choices, our algorithms have found a better option for you.": "I henhold til dine valg har vores algoritmer fundet en bedre mulighed for dig.",
	"See More": "Se mere",

	"10 credits for you!": "10 Credits til dig!",
	"Get 3 free Icebreakers": "Få 3 isbrydere gratis",
	"Free Icebreakers": "Isbrydere gratis",
	"Congratulations, you have received 3 Icebreakers!": "Tillykke, du har modtaget 3 isbryder!",

	"Apple pay only works with the Safari browser. Please open your Safari browser and login to your account in order to proceed with this payment.": "Apple Pay virker kun med Safari browseren. Åbn venligst din Safari browser og log ind på din konto, for at fortsætte betalingen.",

	"No nudity": "Ingen nøgenhed",
	"Nudity allowed": "Nøgenhed tilladt",

	"Edit profile": "Rediger profil",
	"Online": "Online",
	"Step": "Næste",
	"Completed": "Fuldført",
	"Help": "Hjælp",
	"or Drag and drop": "eller træk og slip",

	"You will be able to send messages in": "Du vil kunne sende beskeder om",
	"Send": "Send",
	"Success": "Vellykket",
	"Start a conversation with {name}": "Begynd en samtale med {name}",
	"Failed": "Mislykket",
	"Congratulations, your account has been successfully created.": "Tillykke, din konto er oprettet",
	"Contact our support": "Kontakt vores support",

	"About": "Om",
	"Scale": "Juster",
	"Rotate": "Rotér",

	"No favorites added": "Ingen favoritter tilføjet",

	"Enter Email": "Indtast Email",

    "We have sent you an email to:": "Vi har sendt dig en e-mail til:",
    "Please confirm by clicking the \"delete account\"  button in that email and your account will be permanently deleted from our system.": "Bekræft venligst ved at klikke på knappen \"Slet konto\" i e-mailen, og din konto vil blive permanent slettet fra vores system",
    "DELETE ACCOUNT": "Slet Konto",

	"Didn\'t find what you were looking for?": "Fandt du ikke det, du ledte efter?",
	"Please send us an email and we will get back to you.": "Send os venligst en mail, så vender vi tilbage til dig.",
	"Or contact us using the form": "Eller kontakt os ved hjælp af formularen.",

	"BOOST YOUR ACCOUNT": "BOOST DIN KONTO",
	"We will send you an email to boost your account:": "Vil vil sende dig en e-mail for at booste din konto:",
	"Get Free exclusive benefits": "Få eksklusive fordele gratis",
	"Premium Status 24h": "Premium status i 24 timer",
	"5 Free Credits": "5 Credits gratis",
	"3 Free Icebreakers": "3 Gratis isbrydere",
	"IMPROVE ACCOUNT": "FORBEDRE KONTO",

	"COMPLETE REGISTRATION": "FULD REGISTRERING",
	"Confirm your email and complete registration": "Bekræft din e-mail og fuldfør registreringen",
	"Benefits": "Fordele",
	"More Visibility": "Mere synlighed",
	"Send Messages": "Send beskeder",

    "Your registration is completed": "Din tilmelding er gennemført",

    "Disclaimer": "Ansvarsfraskrivelse",
    "Physical contact may not be possible in all situations. The primary goal of {name} is entertainment, not facilitating in-person meetings or dating. {name} uses fictitious profiles, marked with a heart icon, which are intended solely for messaging and entertainment purposes.": "Fysisk kontakt er ikke mulig i alle situationer. Hovedformålet med {name} er underholdning, ikke at fremme fysiske møder eller dates. {name} bruger fiktive profiler, markeret med et hjerteikon, hvis eneste formål er at give underholdning gennem beskeder.",
    "By using this website and its entertainment services, you acknowledge and agree that payments may be processed by a separate entity within our group of companies. This entity operates solely for payment processing purposes and may differ from the operating company providing the entertainment services.": "Ved at bruge dette website og dets underholdningstjenester anerkender og accepterer du, at betalinger kan blive behandlet af en separat enhed inden for vores virksomhedskoncern. Denne enhed opererer udelukkende med henblik på betalingsbehandling og kan være forskellig fra det opererende selskab, der leverer underholdningstjenesterne.",

    "Free daily icebreaker": "Gratis daglig isbryder",
	"We will send you an email to:": "Vi sender en email til:",
	"Open it and get your free Icebreaker.": "Åbn den og få din gratis isbryder.",
	"Get Icebreaker": "Få Isbryder",
	"Congratulations, you received a free Icebreaker.": "Tillykke, du har modtaget én gratis Isbryder.",

    "Your account has been updated": "Din konto er opdateret",
}
