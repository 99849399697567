<template>
    <div :class="`subscription-default main-con ${!showDesktop ? 'mobile' : ''}`">
        <template v-if="showDesktop">
            <div class="banner-wrapper">
              <main-header />
            </div>
            <div class="content-con">
              <div class="con-wrapper">
                  <slot/>
              </div>
            </div>
            <main-footer />
        </template>
        <template v-else>
          <main-header
            is-mobile @sstate="handleSidebarState"
            :class="[{'intersected': scrollTopPos > 10 }]"
          />
          <mobile-menu
            ref="mobile-menu"
            :init-opened="mobile_menu_opened"
          />
            <div :class="`content-con mobile `" >
                <div :class="`con-wrapper ${mobileNoPad ? 'no-pad' : ''}`">
                  <slot />
                </div>
            </div>
          <base-menu
            :class="[{'show-nav': showNav}, 'bottom-nav']"
            item-class="flex-direction-column"
            :menu="mobile_bottom_menu"
          />
        </template>
    </div>
</template>

<script>
    import MainHeader from '@theme-views/common/MainHeader'
    import MainFooter from '@theme-views/common/MainFooter'
    import BaseMenu from '@theme-views/common/BaseMenu'
    import BaseImage from '@views/common/BaseImage'
    import MobileMenu from '@theme-views/common/MobileMenu'
    import SubscriptionDefault from '@views/user/layouts/SubscriptionDefault/subscription_default'
    import {handleBackBtn} from '@/helpers'

    export default {
        mixins: [SubscriptionDefault],
        data() {
            return {
                sub_menu: [
                    { name: 'My Profile', route: 'user-profile' },
                    { name: 'Messages', route: 'user-messages', sub_title: null },
                    { name: 'Favorites', route: 'user-favorites' },
                    { name: 'Search', route: 'user-search-profiles' },
                    { name: 'Settings', route: 'user-settings' },
                ],
                mobile_bottom_menu: [
                    {
                      name:'Home',
                      route: 'user-home',
                      svg_icon: {
                        name: 'home',
                        width: 27,
                        height: 27,
                        color: '#c5c5c5'
                      }
                    },
                    {
                      name:'Messages',
                      route: 'user-messages',
                      svg_icon: {
                        name: 'envelope',
                        id: 2,
                        width: 29,
                        height: 28,
                        color: '#c5c5c5'
                      }
                    },
                    {
                      name:'Favorites',
                      route: 'user-favorites',
                      svg_icon: {
                        name: 'star',
                        width: 28,
                        height: 28,
                        color: '#c5c5c5'
                      }
                    },
                    {
                      name:'Profile',
                      route: 'user-mobile-home',
                      svg_icon: {
                        name: 'profile',
                        width: 24,
                        height: 28,
                        color: '#c5c5c5'
                      }
                    }
                ],
                mobile_menu_opened: false,
                sidebar_isactive: false,
                headerIntersect: false,
                lastScrollPosition: 0,
                scrollOffset: 40,
                showNav: true
            }
        },
        props: {
            backCallback: {
                type: Function,
                default: null
            },
            scrollTopPos: {
              type: Number,
              default: 0
            }
        },
        methods: {
            openMobileMenu() {
                if(this.$refs['mobile-menu']) {
                    this.$refs['mobile-menu'].openMenu()
                    this.mobile_menu_opened = this.$refs['mobile-menu'].opened
                }
            },
            handleBackClick() {
                if(this.backCallback) {
                    this.backCallback()
                } else {
                    handleBackBtn(this.$router, this.routeFrom, this.backFallbackRoute)
                }
            },
            handleSidebarState(state) {
              this.sidebar_isactive = state
            },
            onScroll() {
              if (this.scrollTopPos < 0) {
                return
              }
              if (Math.abs(this.scrollTopPos - this.lastScrollPosition) < this.scrollOffset) {
                return
              }
              this.showNav = this.scrollTopPos < this.lastScrollPosition
              this.lastScrollPosition = this.scrollTopPos
            }
        },
        computed: {
            routeFrom() {
                return this.$store.getters['pub/routeFrom']
            },
        },
        created() {
          this.lastScrollPosition = this.scrollTopPos
        },
        watch: {
          scrollTopPos() {
            this.onScroll()
          }
        },
        components: {
            MainHeader,
            MainFooter,
            MobileMenu,
            BaseMenu,
            BaseImage
        }
    }
</script>
