import {toFormData} from '../helpers'

export default {
	getOnlineUsers() {
		return Vue.http.get(`api/get-online-users`)
	},
	getGuestDashboard() {
		return Vue.http.get(`api/get-guest-dashboard`)
	},
	getDashboard() {
		return Vue.http.get(`api/get-dashboard`)
	},
	getDashboardUpdates() {
		return Vue.http.get(`api/get-dashboard-updates`)
	},
	updatesMarkAsRead(payload) {
		return Vue.http.post(`api/updates/mark-as-read`, payload)
	},
	getPopularProfiles() {
		return Vue.http.get(`api/get-popular-profiles`)
	},
	getProfile({nick_name, params = {}}){
		return Vue.http.get(`api/get-profile/${nick_name}`, {params})
	},
	getVisitors({params}) {
		return Vue.http.get(`api/visitors`, {params})
	},
	getFavorites({params}) {
		return Vue.http.get(`api/favorites`, {params})
	},
	getOtherFavorites({params}) {
		return Vue.http.get(`api/favorites-others`, {params})
	},
	addFavorite(nick_name) {
		return Vue.http.get(`api/add-favorite/${nick_name}`)
	},
	removeFavorite(nick_name) {
		return Vue.http.get(`api/remove-favorite/${nick_name}`)
	},
	search({params}) {
		return Vue.http.post(`api/search-results`, params)
	},
	getImages() {
		return Vue.http.get(`api/get-images`)
	},
	uploadProfileImage({params}) {
		return Vue.http.post(`api/upload-profile-image`,toFormData(params))
	},
	uploadImage({params}) {
		return Vue.http.post(`api/upload-image`,toFormData(params))
	},
	uploadPrivateImage({params}) {
		return Vue.http.post(`api/upload-private-image`,toFormData(params))
	},
	updateUploadedImage({params}) {
		return Vue.http.post(`api/update-uploaded-image`,toFormData(params))
	},
	deleteImage(id) {
		return Vue.http.get(`api/delete-image/${id}`,)
	},
	getWebCamUrl() {
		return Vue.http.get(`api/cams`)
	}
}
