import profileService from "@services/profile";
import {md5} from "@/helpers";

export default {
  namespaced: true,
  state: {
    maxRecords: 1,
    responses: []
  },
  getters: {
    responses(state) {
      return state.responses || []
    },
    findResponseByHash(state, getters) {
      return hash => getters.responses.find(response => response.hash === hash) || null;
    }
  },
  mutations: {
    delResponse(state, hash) {
      state.responses = state.responses.filter(responses => responses.hash !== hash);
    },
    addResponse(state, {hash, response}) {
      let newResponse = Object.assign({hash, expires: (new Date()).getTime() + 120 * 1000}, {data: response.data});
      if (newResponse.data?.metadata) {
        delete newResponse.data.metadata
      }
      state.responses.push(newResponse)
      state.responses = state.responses.slice(-1 * state.maxRecords)
    }
  },
  actions: {
    getResponseByHash(context, hash) {
      let response = context.getters['findResponseByHash'](hash)
      if (response) {
        if (response.expires > (new Date()).getTime()) {
          return Object.assign({}, response)
        }
        context.commit('delResponse', hash)
      }
      return null
    },
    getSearchResults(context, params) {
      if (params.nearby_distance) {
        let profile = context.rootGetters['auth/profile']
        params.city_id = profile.city_id || profile.province_id || null
      }
      let orderedParams = Object.keys(params).sort().reduce(
        (obj, key) => {
          let val = params[key];
          obj[key] = isNaN(val) ? val : String(val)
          return obj
        },
        {}
      )
      let hash = md5(JSON.stringify(orderedParams))
      return new Promise(async (resolve, reject) => {
        let response = await context.dispatch('getResponseByHash', hash)
        if (response) {
          resolve(response)
        } else {
          profileService.search({params}).then(response => {
            context.commit('addResponse', {hash, response})
            resolve(response)
          }).catch(error => {
            reject(error);
          })
        }
      })
    }
  }
}
