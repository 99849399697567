import * as StackBlur from 'stackblur-canvas'

export default {
	name: 'base-image',
	data() {
		return {
			imgDataNT: null,
			imgData: null,
			loading: true,
			errorTransform: false,
            with_blur: window.APP_CONFIG.WITH_BLUR,
		}
	},
	props: {
		alt: {
			type: String,
			default: ''
		},
		image: {
			type: String,
			default: ''
		},
		imgClasses: {
			type: String,
			default: ''
		},
		blurImage: {
			type: Boolean,
			default: false
		},
		blurSize: {
			type: Number,
			default: 50
		}
	},
	methods: {
		transform(image_url, blur_size) {
			return new Promise((resolve, reject) => {
				let type = image_url.split('.').pop()
				let imgObj = new Image()
				imgObj.crossOrigin = 'Anonymous'
				imgObj.src = image_url + "?not-from-cache"
				imgObj.onload = (e) => {
	                var natW = e.target.naturalWidth
	                var natH = e.target.naturalHeight

					var cvs, cvs2
					cvs = document.createElement('canvas')
					cvs2 = document.createElement('canvas')
	                cvs2.width = cvs.width = natW
	                cvs2.height = cvs.height = natH
					cvs.getContext("2d").drawImage(e.target, 0, 0, natW, natH)
					cvs2.getContext("2d").drawImage(e.target, 0, 0, natW, natH)
					StackBlur.image(e.target, cvs2, blur_size, false)
					resolve({ 
						original: cvs.toDataURL(type, 1),
						transfromed: cvs2.toDataURL(type, 1) 
					})
				}
				imgObj.onerror = reject
			})
		},
		dispatchTransform() {
			this.loading = true
			this.errorTransform = false
			this.transform(this.image, this.blurSize).then(res => {
				this.imgDataNT = res.original
				this.imgData = (this.with_blur) ? res.transfromed : res.original
				this.loading = false
			}, res => {
				this.errorTransform = true
				this.loading = false
				// console.log(res)
			})
		}
	},
	computed: {
		transformedImage() {
			return this.errorTransform ? this.image : (this.blurImage ? this.imgData : this.imgDataNT)
		}
	},
	created() {
		this.dispatchTransform()
	},
	watch: {
		image(to, from) {
			if(to != from) this.dispatchTransform()
		}
	}
}