<template>
    <div id="scrollable-con" ref="app-con" class="app-container full-height" >
        <component
          v-if="layout"
          :is="layout"
          :show-desktop="showDesktop"
          :scroll-top-pos="scrollYPos"
        >
          <transition name="slide-fade" mode="out-in">
            <router-view :key="$route.fullPath"/>
          </transition>
        </component>
        <base-modal
            v-if="modal.open"
            ref="base-modal"
            @close-modal="closeModal"
            :key="modal.name"
            :hasCloseButton="modal.hasCloseButton"
            :alignCenter="modal.alignCenter"
            :forceMobile="modal.forceMobile"
            :extraClasses="modal.extraClasses"
            :canCloseBackDrop="modal.canCloseBackDrop"
            :layoutType="modal.layoutType"
            :mobileBreakpoint="modal.mobileBreakpoint"
            :onClose="modal.onClose"
            :window-height="wHeight"
        >
            <component
                :is="modal.name"
                v-bind="modal.props"
                @close-modal="closeModal"
            />
        </base-modal>

        <notify-pop-up v-if="openNotifPopUp" />
        <discount-pop-up v-if="openDiscountPopUp" />
        <user-notif ref="user-notif" has-close />

        <component
            :is="dynamicComponent.name"
            v-bind="dynamicComponent.props"
            v-if="dynamicComponent"
        />

        <toast
            v-if="toastMsg"
            :type="toastType"
            :cdn-url="cdn_url"
            :message="toastMsg"
            @hide="handleToastClose"
        />
    </div>
</template>

<script>
    import { checkNull, safeRedirect } from '@/helpers'
    import cookies_app from "@views/common/CookiesSettingsModal/cookies_app"
    import { layouts } from '@theme-views/layouts'
    import promotionMixin from '@/common/promotion'

    delete cookies_app.methods.openCookiesNotification
    delete cookies_app.methods.openCookiesSettings

    export default {
        name: 'app',
        mixins: [cookies_app, promotionMixin],
        metaInfo() {
            let meta = {
                title: this.title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.description },
                    { vmid: 'keywords', name: 'keywords', content: this.keywords }
                ]
            }
            if (window.APP_CONFIG.CANONICAL_URL) {
                let url = window.APP_CONFIG.CANONICAL_URL;
                let pattern = new RegExp('^(https?|ftp)://');
                if (!pattern.test(url)) {
                    url = window.location.origin + '/' + url.replace(/^\//g, '');
                }
                meta.link = [{ vmid: 'canonical', rel: 'canonical', href: url }];
            }
            return meta;
        },
        data() {
            return {
                icdn_url: window.APP_CONFIG.ICDN_URL,
                referer: window.APP_CONFIG.REFERER,
                cdn_url: window.APP_CONFIG.CDN_URL,
                is_dev: process.env.NODE_ENV !== 'production',
                title: '',
                description: '',
                keywords: '',
                loading: false,
                isLandscape: false,
                modal: {
                    open: false,
                    name: '',
                    hasCloseButton: false,
                    alignCenter: false,
                    canCloseBackDrop: true,
                    props: {}
                },
                width: window.innerWidth,
                wHeight: window.innerHeight,
                scrollYPos: 0,
                deviceInfo: {},
                dynamicComponent: null,
                toastMsg: null,
                toastType: 'success',
            }
        },
        methods: {
            openCookiesNotification() {
                this.closeModal();
                this.changeModalContent({
                    name: 'cookies-notification-modal',
                    hasCloseButton: false,
                    alignCenter: false,
                    canCloseBackDrop: false,
                    layoutType: 'medium',
                    extraClasses: 'base-modal-cookies centered t-initial'
                })
                this.openModal()
            },
            openCookiesSettings(options = {}) {
                this.closeModal();
                this.changeModalContent({
                    name: 'cookies-settings-modal',
                    hasCloseButton: true,
                    onClose: () => {},
                    alignCenter: false,
                    canCloseBackDrop: false,
                    layoutType: 'medium',
                    extraClasses: 'base-modal-cookies centered t-initial',
                    ...options
                })
                this.openModal()
            },
            changeModalContent({name, hasCloseButton, alignCenter, canCloseBackDrop, layoutType, forceMobile, extraClasses, mobileBreakpoint, onClose, props}) {
                this.modal.name = name
                this.modal.hasCloseButton = hasCloseButton
                this.modal.alignCenter = alignCenter
                this.modal.canCloseBackDrop = canCloseBackDrop
                this.modal.layoutType = layoutType
                this.modal.forceMobile = forceMobile
                this.modal.extraClasses = extraClasses
                this.modal.mobileBreakpoint = mobileBreakpoint
                this.modal.onClose = onClose
                this.modal.props = props
            },
            handleToastMsg(toastInfo) {
                this.toastMsg = toastInfo.msg
                this.toastType = toastInfo.type
            },
            handleToastClose() {
                this.toastMsg = null

                EventBus.$emit('toast-on-close')
            },
            setDynamicComponent(component) {
                this.dynamicComponent = component
            },
            openModal() {
                this.$refs['app-con'].classList.add('modal-open')
                this.modal.open = true
            },
            closeModal() {
                this.$refs['app-con'].classList.remove('modal-open')
                this.modal.open = false
            },
            handleResize(e) {
                this.width = e.target.innerWidth
                this.wHeight = e.target.innerHeight
                this.detectDevice()
            },
            setMetas(route) {
                this.title = route.meta.title
                this.description = route.meta.description
                this.keywords = route.meta.keywords
            },
            logout() {
                this.$store.dispatch('auth/logout')
                    .then(res => {
                        this.$router.push({ name: 'logged-out', params: { status: 'success', fromRemoveAuth: true } })
                    }, res =>{
                        this.$router.push({ name: 'logged-out', params: { status: 'success', fromRemoveAuth: true } })
                    })
            },
            redirectWebCamUrl() {
                this.$store.dispatch('profile/getWebCamUrl')
                    .then(res => {
                        // let url = encodeURIComponent(res.data.url)
                        // window.open(window.location.origin+'/redirect-url?url='+url, '_blank')
                        safeRedirect(res.data.url)
                    }, res => {
                        console.log(res)
                        this.$router.push({name: 'home'})
                    })
            },
            resetDataLoaded() {
                this.$store.dispatch('profile/populateProfiles')
                this.$store.dispatch('pub/getFormValues')
                this.$store.dispatch('pub/getCityPages')
            },
            modifyCookies() {
                if(['google', 'bing'].indexOf(this.referer) !== -1) {
                    let sd_config = this.$store.getters['cookies/getSdConfig']
                    let append_config = {}
                    append_config.s1 = this.referer
                    append_config.s2 =  window.location.hostname
                    sd_config = {...sd_config, ...append_config}
                    this.$store.dispatch('cookies/setSdConfig', sd_config);
                }
            },
            checkIfInactiveUser() {
                // list of routes that should not show this modal
                let a_routes = ['user-credits', 'user-credits-payment','user-premium', 'user-settings', 'support', 'user-delete-account']
                let dont_show = (a_routes.indexOf(this.$route.name) !== -1)
                if(this.isInActive && !dont_show) {
                    // add check if user is inactive
                    this.changeModalContent({
                        name: 'inactive-user-pop-up',
                        hasCloseButton: false,
                        alignCenter: true,
                        canCloseBackDrop: false,
                        layoutType: "medium",
                        extraClasses: "inactive-user"
                    })
                    this.openModal()
                } else {
                    if (this.modal.name === 'inactive-user-pop-up') this.closeModal()
                    if (this.$store.getters['auth/isAuthenticated'] && this.$store.getters['auth/trialEnded'] && ['user-activate-profile'].indexOf(this.$route.name) === -1) {
                        this.$store.dispatch('trial/end', (params) => {
                            this.$router.push({name: 'user-activate-profile', params})
                        })
                    }
                }
            },
            handleAppScroll(e) {
                this.scrollYPos = e.target.scrollingElement.scrollTop
                EventBus.$emit('appScrolled', e.target.scrollingElement.scrollTop)
            },
            resetMobileScroll() {
              if(!this.showDesktop) {
                setTimeout(() => {
                  this.$refs['app-con'].scrollTop = 0
                }, 320)
              }
              document.body.scrollTop = 0
            },
            detectDevice() {
              if(this.showDesktop) {
                this.isLandscape = false
              } else {
                this.isLandscape = window.matchMedia("(orientation: portrait)").matches ? false : true
              }
            },
            disableParentScrolls(param) {
                if(!param) {
                    this.addHtmlClass(false, 'body', 'scroll-overlay-host')
                    this.addHtmlClass(false, 'html', 'scroll-overlay-host')
                } else {
                    this.addHtmlClass(true, 'body', 'scroll-overlay-host')
                    this.addHtmlClass(true, 'html', 'scroll-overlay-host')
                }
            },
            scrollListener() {
                let self = this
                document.addEventListener('scroll', function(e) {
                    self.handleAppScroll(e)
                });
            },
            handleFullHeight(isFullHeight) {
                if (isFullHeight && !this.showDesktop ) {
                    this.addHtmlClass(true, 'html', 'm-browser-ui')
                } else {
                    this.addHtmlClass(false, 'html', 'm-browser-ui')
                }
            },
            addHtmlClass(isAdd, elm, className) {
                isAdd ? document.querySelector(elm).classList.add(className) : document.querySelector(elm).classList.remove(className)
            },
            isLandingView(isAdd) {
                isAdd ? this.$root.$children[0].addHtmlClass(true, '#scrollable-con', 'landing--view') : this.$root.$children[0].addHtmlClass(false, '#scrollable-con', 'landing--view')
            },
            openCompleteRegister() {
                this.changeModalContent({
                    name: 'complete-register-pop-up',
                    hasCloseButton: true,
                    alignCenter: true,
                    canCloseBackDrop: false,
                    layoutType: "medium",
                    extraClasses: "small-header",
                    mobileBreakpoint: 1199,
                    onClose: () => {
                        this.$store.dispatch("auth/denyCompleteRegister").then(() => {
                            this.$store.dispatch("auth/getUser")
                        })
                    }
                })
                this.openModal()
            },
            openBoostAccount() {
                this.changeModalContent({
                    name: 'boost-account-pop-up',
                    hasCloseButton: true,
                    alignCenter: false,
                    canCloseBackDrop: false,
                    layoutType: "medium",
                    extraClasses: "small-header",
                    mobileBreakpoint: 1199,
                    onClose: () => {
                        this.$store.dispatch("auth/denyBoostProfile").then(() => {
                            this.$store.dispatch("auth/getUser")
                        })
                    }
                })
                this.openModal()
            },
        },
        created() {
            window.addEventListener("resize", this.handleResize)
            this.$on('open-modal', this.openModal)
            this.$on('close-modal', this.closeModal)
            this.$on('change-modal-content', this.changeModalContent)
            //fix for ios10 and below devices rendering
            this.$nextTick(() => {
                window.scrollTo(0, 1)
                window.scrollTo(0, 0)
            })
            this.detectDevice()
            window.onbeforeunload = () => {
                if (!this.$store.getters['pub/allowLeave']) {
                    if(!this.isGoingExternal) return;
                    return 'Are you sure you wish to leave?'; // not what actually gets displayed in most browsers
                }
            }

            this.scrollListener()
        },
        beforeDestroy() {
            window.removeEventListener("resize", this.handleResize)
            this.$off('open-modal', this.openModal)
            this.$off('close-modal', this.closeModal)
            this.$off('change-modal-content', this.changeModalContent)

            this.scrollListener()
        },
        computed: {
            isInActive() {
                return Boolean(this.$store.getters['auth/profile']['inactive'])
            },
            openNotifPopUp() {
                return this.$store.getters['auth/isAuthenticated'] && !this.$store.getters['auth/webPushInitialized']
                    && this.$store.getters['auth/openNotifPopUp'] && !this.$store.getters['auth/isOnTrial']
            },
            layout() {
              const parentRoute = this.$route.matched.find(route => ! route.parent)

              if (! parentRoute) {
                return null
              }

              return layouts[parentRoute.meta.layout]
            },
            showDesktop() {
                return this.$root.$children[0].width > 1199
            },
            hasFullHTMLHeight() {
                return (this.layout && this.layout.name === 'PublicLayout' || this.$route.name === 'user-mobile-home')
            },
            isGoingExternal() {
                const routeTo = this.$store.getters['pub/routeTo']
                let fromInside = checkNull(routeTo) && routeTo.indexOf(window.location.hostname) !== -1
                return !fromInside
            },
            openDiscountPopUp() {
                return this.$store.getters['auth/isAuthenticated'] && this.$store.getters['payment/openDiscountPopUp']
            }
        },
        mounted() {
            this.setMetas(this.$route)
            if(this.$store.getters['auth/isAuthenticated']) {
                this.$store.dispatch('auth/getUser', {
                    success_cb: (res) => {
                        this.checkIfInactiveUser();
                    }
                })
                this.$store.dispatch('message/getIcebreakers')
            }
            this.resetDataLoaded()
        },
        watch: {
            showDesktop(newVal) {
                 this.handleFullHeight(this.hasFullHTMLHeight ? true : false)
            },
            '$route' (to, from) {
                this.setMetas(to)

                this.$refs['app-con'].classList.remove('modal-open')
                this.$root.$children[0].$refs['app-con'].classList.remove('sidebar-active')

                EventBus.$emit('close-sidebar')
                document.body.style['overflow-y'] = null

                if(!this.isInActive && this.modal.open && typeof(this.$refs['base-modal']) != 'undefined') this.$refs['base-modal'].closeModal()
                if(checkNull(to.params.fromRemoveAuth)) this.resetDataLoaded()

                this.checkIfInactiveUser()
                this.resetMobileScroll()

                // mobile
                this.handleFullHeight(this.hasFullHTMLHeight ? true : false)
                this.isLandingView(this.$route.name === 'home' && !this.showDesktop || this.$route.name === 'login' && !this.showDesktop ? true : false)
            },
            isLandscape(newVal) {
              EventBus.$emit('orientation-change', newVal)
            },
            deviceInfo(newVal) {
              this.isLandscape = newVal.device === 'mobile' && newVal.orientation === 'landscape' ? true : false
            },
            hasFullHTMLHeight(newVal) {
                this.handleFullHeight(newVal)
            },
            modal: {
                handler(newVal) {
                    document.getElementById('scrollable-con').style.overflow = newVal.open ? 'hidden' : null
                    this.disableParentScrolls(newVal.open)
                },
                deep: true
            },
            layout(newVal) {
                newVal && newVal.name === 'PublicLayout' ? document.body.style['background'] = 'transparent' :  document.body.style['background'] = null
            }
        },
        components: {
            BaseModal: () => import(/* webpackChunkName: "/app/common/BaseModal" */ '@theme-views/common/BaseModal'),
            UserNotif: () => import(/* webpackChunkName: "/app/common/UserNotif" */ '@views/common/UserNotif'),
            RegisterErrorPopUp: () => import(/* webpackChunkName: "/app/user/partials/RegisterErrorPopUp" */ '@theme-views/user/partials/RegisterErrorPopUp'),
            RegisterFollowUpPopUp: () => import(/* webpackChunkName: "/app/user/partials/RegisterFollowUpPopUp" */ '@theme-views/user/partials/RegisterFollowUpPopUp'),
            PRegisterPopUp: () => import(/* webpackChunkName: "/app/public/partials/PRegisterCard/PopUp" */ '@theme-views/public/partials/PRegisterCard/PopUp'),
            LoginPopUp: () => import(/* webpackChunkName: "/app/public/pages/Login/PopUp" */ '@theme-views/public/pages/Login/PopUp'),
            ConfirmPopUp: () => import(/* webpackChunkName: "/app/user/partials/ConfirmPopUp" */ '@theme-views/user/partials/ConfirmPopUp'),
            PaymentBlikFillData: () => import(/* webpackChunkName: "/app/user/partials/PaymentBlikFillData" */ '@theme-views/user/partials/PaymentBlikFillData'),
            IcebreakerPromoPopUp: () => import(/* webpackChunkName: "/app/user/partials/IcebreakerPromoPopUp" */ '@theme-views/user/partials/IcebreakerPromoPopUp'),
            PaymentAccountDetails: () => import(/* webpackChunkName: "/app/user/partials/PaymentAccountDetails" */ '@theme-views/user/partials/PaymentAccountDetails'),
            PaymentFinish: () => import(/* webpackChunkName: "/app/user/partials/PaymentFinish" */ '@theme-views/user/partials/PaymentFinish'),
            PaymentModalCardTrustpay: () => import(/* webpackChunkName: "/app/user/partials/PaymentModal/CardTrustpay" */ '@theme-views/user/partials/PaymentModal/CardTrustpay'),
            PaymentModalBlikTrustpay: () => import(/* webpackChunkName: "/app/user/partials/PaymentModal/BlikTrustpay" */ '@theme-views/user/partials/PaymentModal/BlikTrustpay'),
            PaymentModalWireTrustpay: () => import(/* webpackChunkName: "/app/user/partials/PaymentModal/WireTrustpay" */ '@theme-views/user/partials/PaymentModal/WireTrustpay'),
            InactiveUserPopUp: () => import(/* webpackChunkName: "/app/user/partials/InactiveUserPopUp" */ '@theme-views/user/partials/InactiveUserPopUp'),
            MessagePopUp: () => import(/* webpackChunkName: "/app/user/partials/MessagePopUp" */ '@theme-views/user/partials/MessagePopUp'),
            MListCheckboxesPopUp: () => import(/* webpackChunkName: "/app/user/partials/mobile/MListCheckboxesPopUp" */ '@views/user/partials/mobile/MListCheckboxesPopUp'),
            ActivatePopUp: () => import(/* webpackChunkName: "/app/user/partials/ActivatePopUp" */ '@theme-views/user/partials/ActivatePopUp'),
            DeleteUserWithPromo: () => import(/* webpackChunkName: "/app/user/partials/DeleteUserWithPromo" */ '@theme-views/user/partials/DeleteUserWithPromo'),
            DeleteUserConfirmPopup: () => import(/* webpackChunkName: "/app/user/partials/DeleteUserConfirmPopUp" */ '@theme-views/user/partials/DeleteUserConfirmPopUp'),
            CompleteRegisterPopUp: () => import(/* webpackChunkName: "/app/user/partials/CompleteRegisterPopUp" */ '@theme-views/user/partials/CompleteRegisterPopUp'),
            CompletedRegisterPopUp: () => import(/* webpackChunkName: "/app/user/partials/CompleteRegisterPopUp" */ '@theme-views/user/partials/CompletedRegisterPopUp'),
            BoostAccountPopUp: () => import(/* webpackChunkName: "/app/user/partials/BoostAccountPopUp" */ '@theme-views/user/partials/BoostAccountPopUp'),
            BoostedProfilePopUp: () => import(/* webpackChunkName: "/app/user/partials/BoostAccountPopUp" */ '@theme-views/user/partials/BoostedProfilePopUp'),
            InactiveUserPromo: () => import(/* webpackChunkName: "/app/user/partials/InactiveUserPromo" */ '@theme-views/user/partials/InactiveUserPromo'),
            NotifyPopUp: () => import(/* webpackChunkName: "/app/user/partials/NotifyPopUp" */ '@theme-views/user/partials/NotifyPopUp'),
            Toast: () => import(/* webpackChunkName: "/app/common/Toast" */  '@theme-views/common/Toast'),
            DiscountPopUp: () => import(/* webpackChunkName: "/app/user/partials/DiscountPopUp" */ '@theme-views/user/partials/DiscountPopUp'),
        }
    }
</script>

<style lang="scss">
@import '~sass/app.scss';

// .app-container {

// }
</style>
